<template>
    <Dialog :dialog="dlg">
        <template v-slot:toolbar-left>
            <ToolbarField label="Status">
                <StatusColumn :model="model"></StatusColumn>
            </ToolbarField>
            <ToolbarField class="ml-8" label="Factuur">
                <span v-if="model.isInvoiced">
                    <ActionButton action="salesinvoice.open" class="text-regular" @click="() => onOpenInvoice()" link-button icon="link">{{model.invoiceRep}}</ActionButton>         
                </span>
                <span v-else>
                    -
                </span>
            </ToolbarField>
        </template>
        <template v-slot:toolbar-right>
            <ActionButton ml :disabled="model.isDataLoading" icon="pdf" xmain xprimary @click='()=>pdfDownload()'>Download</ActionButton>
            <ActionButton ml :disabled="model.isDataLoading" icon="pdf" xmain xprimary @click='()=>pdfExample()'>Voorbeeld</ActionButton>
            <ActionButton action="manday.invoice" v-if="!model.isInvoiced" ml :disabled="model.isDataLoading" icon="invoice" @click='()=>createInvoice()'>Maak factuur</ActionButton>
        </template>
        <template v-slot:nu-even-niet-toolbar-menu>
            <ActionMenuItem action="manday.download" @click="()=>downloadManday()">Download</ActionMenuItem> 
            <ActionMenuItem action="manday.send" @click="()=>sendManday()">Verstuur mandagenstaat</ActionMenuItem> 
        </template>        

        <v-form ref="form" lazy-validation>
            <v-card class="bg-card">
                <v-card-text>
                    <v-row dense>
                        <v-col sm="6" xl="4" class="pr-4">
                            <FormRow col-label="3" label="Aan">            
                                <RelationCombo :model="model" sales :rules="model.rules.id_relation" v-model="model.id_relation"></RelationCombo>
                            </FormRow>
                            <FormRow col-label="3" label="Jaar / Week" >            
                                <v-row dense>
                                    <v-col>
                                        <YearCombo :model="model" no-clearable :rules="model.rules.mdr_year" v-model="model.mdr_year"></YearCombo>
                                    </v-col>          
                                    <v-col>
                                        <WeekCombo :model="model" appendIcon="icon-calendar-01" no-clearable :rules="model.rules.mdr_week" v-model="model.mdr_week">
                                        </WeekCombo>
                                    </v-col>          
                                </v-row>
                            </FormRow>
                            <FormRow col-value-right >            
                                <DateButton :model="model" v-model="model.pickPeriod">
                                    <template v-slot:prepend>
                                        <span class="">{{model.periodFrom}}</span>
                                        <span class=" mx-2">-</span>
                                        <span class="">{{model.periodTo}}</span>
                                    </template>
                                </DateButton>

                            </FormRow>
                        </v-col>
                        <v-col sm="6" xl="4" class="pl-4">

                            <FormRow col-label="3" label="Titel">            
                                <TextField max-length="255" :model="model" :rules="model.rules.mdr_title"  placeholder="Omschrijving" v-model="model.mdr_title"></TextField>
                            </FormRow>
                            <FormRow label-v-top col-label="3" label="Opmerking" >     
                                <TextArea max-length="4096" :model="model" placeholder="Opmerking" v-model="model.mdr_comment"></TextArea>
                                <span class="text-small text-italic">Opmerking wordt onderin de mandagenstaat getoond</span>
                            </FormRow>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    

            <v-card class="mt-4">

                <LocalTable :model="model" modelLinesProp='lines' no-hover dense :dt="tblLines" editable>

                    <template v-slot:header.mdrr_monday>   Ma {{model.dtMon}}</template>
                    <template v-slot:header.mdrr_tuesday>  Di {{model.dtTue}}</template>
                    <template v-slot:header.mdrr_wednesday>Wo {{model.dtWed}}</template>
                    <template v-slot:header.mdrr_thursday> Do {{model.dtThu}}</template>
                    <template v-slot:header.mdrr_friday>   Vr {{model.dtFri}}</template>
                    <template v-slot:header.mdrr_saturday> Za {{model.dtSat}}</template>
                    <template v-slot:header.mdrr_sunday>   Zo {{model.dtSun}}</template>

                    <template v-slot:item.id_employee="{ item }">
                        <EmployeeCombo no-border no-clearable :model="model" :rules="model.rules.mandatory" v-model="item.id_employee"></EmployeeCombo>
                    </template>
                    <template v-slot:item.mdrr_work_description="{ item }">
                        <TextField max-length="255" no-border :model="model" v-model="item.mdrr_work_description" placeholder="Beschrijving werzaamheden (optioneel) ..."></TextField>
                    </template>
                    <template v-slot:item.mdrr_monday="{ item }">
                        <TimeField :hm="model.hm" :model="model" no-border class="hours" v-model="item.mdrr_monday"></TimeField>
                    </template>
                    <template v-slot:item.mdrr_tuesday="{ item }">
                        <TimeField :hm="model.hm" :model="model" no-border class="hours" v-model="item.mdrr_tuesday" ></TimeField>
                    </template>
                    <template v-slot:item.mdrr_wednesday="{ item }">
                        <TimeField :hm="model.hm" :model="model" no-border class="hours" v-model="item.mdrr_wednesday" ></TimeField>
                    </template>
                    <template v-slot:item.mdrr_thursday="{ item }">
                        <TimeField :hm="model.hm" :model="model" no-border class="hours" v-model="item.mdrr_thursday" ></TimeField>
                    </template>
                    <template v-slot:item.mdrr_friday="{ item }">
                        <TimeField :hm="model.hm" :model="model" no-border class="hours" v-model="item.mdrr_friday" ></TimeField>
                    </template>
                    <template v-slot:item.mdrr_saturday="{ item }">
                        <TimeField :hm="model.hm" :model="model" no-border class="hours" v-model="item.mdrr_saturday" ></TimeField>
                    </template>
                    <template v-slot:item.mdrr_sunday="{ item }">
                        <TimeField :hm="model.hm" :model="model" no-border class="hours" v-model="item.mdrr_sunday" ></TimeField>
                    </template>
                    <template v-slot:item.totalMinutes="{ item }">
                        <TimeText :hm="model.hm" class="" :value="item.totalMinutes"></TimeText>
                    </template>

                    <template v-slot:item.cmd-remove="{ item }">
                        
                        <ActionButton action="manday.modify" :model="model" v-if="!model.disabled" ml icon-button icon="remove"  
                            @click="() => model.removeLine(item)">
                        </ActionButton>

                    </template>

                    <template v-slot:tfoot>
                        <tfoot >
                            <tr>
                                <td class="py-4" colspan="2">
                                    <ActionButton action="manday.modify" :model="model" v-if="!model.disabled" class="ml-2" icon="create" sub @click='()=>model.addLine()'>Nieuwe regel</ActionButton>
                                </td>
                                <td colspan="7" class="py-4 text-right">
                                    
                                </td>
                                <td class="py-4 pr-3 text-right">

                                    <h2 @click="model.hm=!model.hm"><TimeText :hm="model.hm" :model="model" class="text-right" :value="model.totalMinutes"></TimeText></h2>
                                    
                                </td>
                                <td></td>
                            </tr>
                        </tfoot>
                    </template>

                </LocalTable>

            </v-card>
        </v-form>

    </Dialog>
</template>



<script setup>
    import useModel from '@/models/manday'
    import Form from '@controls/forms/Form'
    import FormColumn from '@controls/forms/FormColumn'
    import FormRow from '@controls/forms/FormRow'
    import TextField from '@controls/input/TextField'
    import Dialog from '@shared/ui/dialogs/ActionDialogModal'
    import ActionButton from '@shared/ui/controls/buttons/ActionButton'
    import ActionMenuItem from "@controls/menu/ActionMenuItem.vue";
    import StatusColumn from '@/views/manday/StatusColumn'
    import RelationCombo from '@controls/combo/Relation'
    import TextArea from '@controls/input/TextArea'
    import YearCombo from '@controls/combo/Year'
    import WeekCombo from '@controls/combo/Week'
    import DateButton from '@controls/buttons/DateButton'
    import { ref, watch } from 'vue'
    import clsDialog from '@cls/clsDialog'
    import TimeText from "@controls/text/Time.vue";
    import eventbus from '@app/eventbus'
    import noty from '@lib/noty'
    import ToolbarField from '@shared/ui/dialogs/ToolbarField'
    import LocalTable from '@datatable/LocalTable'  
    import clsLocalTable from '@cls/clsLocalTable'
    import EmployeeCombo from '@controls/combo/Employee'
    import appDlg from '@app/dlg'

    import TimeField from '@controls/input/TimeField'

    const form = ref(null); 

    let model = useModel();
    let dlg = ref(
        new clsDialog("manday", "Mandagstaat", model, [form], 
        {
            noteType: 'manday_note',
            flagType: 'manday_flag',
            attachmentType: 'manday_attachment',
            keepOpen: true,
            // sm:12,lg:10,xl:8
        }
    )).value;  //TODO

//    watch(['model.id_relation' /*, this.mdr_week, this.mdr_year */], (newValue, oldValue) => {
//        console.log('field change', newValue, oldValue)
//    })

    /**
     * Generate a pdf example. Save first 
     */
    async function pdfExample() {
        var result = true;
        if (!model.disabled) {
            result = await dlg.onSave(true /* keep dialog open */)
        }
        if (result) {
            eventbus.dialog.pdfviewer(async () => model.downloadAsPdf(), `Mandagstaat ${model.modelRep}`);
        }
    }
    async function pdfDownload() {
        var result = true;
        if (!model.disabled) {
            result = await dlg.onSave(true /* keep dialog open */)
        }
        if (result) {
            await model.downloadAsPdfToClient();
        }
    }

    /**
     * Copy this manday registration. 
     */
    async function copyManday() {

        var result = await dlg.onSave(false /* keep dialog open */)
        if (result) {
             appDlg.copy('manday', {id: model.id})
        }
    }

    /**
     * Copy this manday registration. 
     */
    async function createInvoice() {        
        await noty.confirm('Weet u zeker dat u een conceptfactuur wilt maken van deze mandagenstaat?<br>Na het aanmaken van de factuur wordt deze geopend.');
        // Do not save when the model is disabled. (e.g. readonly rights)
        if (!model.disabled) {
            var result = await dlg.onSave(true /* keep dialog open */)
            if (!result) {
                return;
            }
        }
        let id_invoice = await model.createInvoice();
        if (id_invoice) {
            dlg.closeDialog();
            appDlg.open('salesinvoice', {id: id_invoice})
        }
    }

    /**
     * Open our invoice
     */
    async function onOpenInvoice() {
        appDlg.open('salesinvoice', {id: model.id_invoice})
    }



    let headers = [/*
        <th class="px-1 text-left col-employee">Medewerker</th>
        <th class="px-1 text-left col-employee">Werkzaamheden</th>
        <th class="pl-1 pr-4 text-right col-day">Ma<div class="text-smaller text-right opacity-70">{{model.dtMon}}</div></th>
        <th class="pl-1 pr-4 text-right col-day">Di<div class="text-smaller text-right opacity-70">{{model.dtTue}}</div></th>
        <th class="pl-1 pr-4 text-right col-day">Wo<div class="text-smaller text-right opacity-70">{{model.dtWed}}</div></th>
        <th class="pl-1 pr-4 text-right col-day">Do<div class="text-smaller text-right opacity-70">{{model.dtThu}}</div></th>
        <th class="pl-1 pr-4 text-right col-day">Vr<div class="text-smaller text-right opacity-70">{{model.dtFri}}</div></th>
        <th class="pl-1 pr-4 text-right col-day">Za<div class="text-smaller text-right opacity-70">{{model.dtSat}}</div></th>
        <th class="pl-1 pr-4 text-right col-day">Zo<div class="text-smaller text-right opacity-70">{{model.dtSun}}</div></th>
        <th class="pl-8 px-1 text-right col-hours">Totaal<div class="text-smaller text-right opacity-70"></div></th>
        <th class="px-1 text-right col-cmd"></th>

        { "text": "Grootboek", "value": "id_ledger", class:"pl-3", visible: settings.use_ledgers}, 
        { "text": "Totaal Excl. BTW", "value": "amount_excl", class:"text-right pr-3"}, 
        { "text": "BTW Bedrag", "value": "amount_vat", class:"text-right pr-3"}, 
        { "text": "", value: "cmd-remove", class: 'cmd'}, 

        */

        { "text": "Medewerker", "value": "id_employee", class:"pl-3 w-6", xstyleHeader:{width:"200px"}}, 
        { "text": "Werkzaamheden", "value": "mdrr_work_description", class:"pl-3", xstyleHeader:{width:"200px"}}, 
        { "text": "Ma", "value": "mdrr_monday",      cellClass: "text-right", class:"w-3 text-right pr-3",  innerCellClass: "inline-block"},
        { "text": "Di", "value": "mdrr_tuesday",     cellClass: "text-right", class:"w-3 text-right pr-3" , innerCellClass: "inline-block"}, 
        { "text": "Wo", "value": "mdrr_wednesday",   cellClass: "text-right", class:"w-3 text-right pr-3" , innerCellClass: "inline-block"}, 
        { "text": "Do", "value": "mdrr_thursday",    cellClass: "text-right", class:"w-3 text-right pr-3" , innerCellClass: "inline-block"}, 
        { "text": "Vr", "value": "mdrr_friday",      cellClass: "text-right", class:"w-3 text-right pr-3" , innerCellClass: "inline-block"}, 
        { "text": "Za", "value": "mdrr_saturday",    cellClass: "text-right", class:"w-3 text-right pr-3" , innerCellClass: "inline-block"}, 
        { "text": "Zo", "value": "mdrr_sunday",      cellClass: "text-right", class:"w-3 text-right pr-3" , innerCellClass: "inline-block"}, 
        { "text": "Totaal", "value": "totalMinutes", cellClass: "text-right pr-3", class:"w-3 text-right pr-3", innerCellClass: "inline-block"}, 
        { "text": "", value: "cmd-remove", class: 'cmd'}, 
    ];

    let tblLines = new clsLocalTable("mandaylines", headers, {
        noDataText: "Er zijn nog geen regels toegevoegd",
        model: model, 
        modelLinesProp: 'lines'        
    });



    window.mandlg = dlg;
    window.mand = model;
</script>
