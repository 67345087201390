<template>

    <ActionCombo :noClearable="noClearable" :placeholder="placeholder" :skeleton="compSkeleton" :disabled="compDisabled" :rules="rules" v-model="dvalue" :items="items" >
    </ActionCombo>

</template>

<script setup>
    import Icon from '@controls/icons/Icon'

    // import WeekCombo from '@controls/combo/Week'
    // <WeekCombo min="-2" max="2" :model="model" :rules="model.rules.week" v-model="model.week"></WeekCombo>

    const props = defineProps({
        disabled: {
            type: [Boolean]
        },
        rules: {
            type: [Array]
        },
        noClearable: {
            type: [Boolean],
            default: false
        },
        skeleton: {
            type: [Boolean]
        },
        value: {
            type: [String, Number]
        },
        placeholder: {
            type: [String],
            default: 'Week'
        },                
        model: {
            type: [Object]
        }
    })
    
    import ActionCombo from '@controls/combo/ActionCombo'
    import {computed, ref} from 'vue'
    import {date} from '@lib/date'

    const emit = defineEmits(['input'])

    const dvalue = computed({
        get() {
            return props.value
        },
        set: function(v) {
            emit('input', v)
        }	
    })
    const compSkeleton = computed({
        get() {
            if (props.skeleton) {
                return true;
            }
            if (!props.model) {
                return false;
            }
            return props.model.isDataLoading;
        }
    });
    const compDisabled = computed({
        get() {
            if (props.disabled) {
                return true;
            }
            if (!props.model) {
                return false;
            }
            return props.model.disabled;
        }
    });


    // items does not need to be reactive
    var items = [];
    for (var n = 1; n <=53;n++) {
        items.push({id: n, name: `${n}`})
    }                        

</script>
