<template>
    <Form sm="12-12" lg="6-6">
        <FormRow v-if="model.isNew" label="">
            <ActionButton link-button @click="()=>onKVK()" action='relation.modify'>Zoek binnen KVK bestand</ActionButton>
        </FormRow>

        <FormRow v-if="model.rel_nr" label="Relatienummer">            
            <TextField disabled :model="model" v-model="model.rel_nr"></TextField>
        </FormRow>
        <FormRow label="Relatienaam">            
            <TextField max-length="255" :rules="model.rules.rel_name" :model="model" placeholder="Relatienaam" v-model="model.rel_name"></TextField>
        </FormRow>
        <FormRow label="KVK Nummer">            
            <TextField max-length="20" required xrules="model.rules.rel_coc_number" :model="model" placeholder="KVK Nummer" v-model="model.rel_coc_number"></TextField>
        </FormRow>
        <FormRow label="BTW Nummer">            
            <TextField max-length="20" :rules="model.rules.rel_vat_number" :model="model" placeholder="BTW Nummer" v-model="model.rel_vat_number"></TextField>
        </FormRow>
            <FormRow label="Adres" subLabel="Hoofdadres">
                <v-row dense >
                    <v-col class="col-9">
                        <TextField max-length="255" placeholder="Straatnaam" :rules="model.rules.adr_street" :model="model" v-model="model.adr_street"></TextField>
                    </v-col>            
                    <v-col class="col-3">
                        <TextField max-length="45" placeholder="Nr" :rules="model.rules.adr_street_number" :model="model" v-model="model.adr_street_number"></TextField>
                    </v-col>            
                </v-row>
            </FormRow>
            <FormRow>
                <v-row dense>
                    <v-col class="col-4">
                        <TextField max-length="45" placeholder="Postcode" :rules="model.rules.adr_zipcode" :model="model" v-model="model.adr_zipcode"></TextField>
                    </v-col>            
                    <v-col class="col-8">
                        <TextField max-length="255" placeholder="Plaats" :rules="model.rules.adr_city" :model="model" v-model="model.adr_city"></TextField>
                    </v-col>            
                </v-row>
            </FormRow>
            <FormRow>            
                <CountryCombo :model="model" :rules="model.rules.mandatory" v-model="model.country_code"></CountryCombo>
            </FormRow>

        <template v-slot:right>
            <FormRow v-if="model.isNew" label="">
                
            </FormRow>

            <FormRow label="E-mail">            
                <TextField max-length="128" :rules="model.rules.rel_email" :model="model" placeholder="E-mail" v-model="model.rel_email"></TextField>
            </FormRow>
            <FormRow label="Factuur e-mail">            
                <TextField max-length="128" :rules="model.rules.rel_email_invoice" :model="model" placeholder="E-mail" v-model="model.rel_email_invoice"></TextField>
            </FormRow>
            <FormRow label="Aanmaning e-mail">            
                <TextField max-length="128" :rules="model.rules.rel_email_reminder" :model="model" placeholder="E-mail" v-model="model.rel_email_reminder"></TextField>
            </FormRow>
            <FormRow label="Telefoonnummer">            
                <TextField max-length="45" :rules="model.rules.rel_phone" :model="model" placeholder="Telefoonnummer" v-model="model.rel_phone"></TextField>
            </FormRow>
            <FormRow label="Mobiel">            
                <TextField max-length="45" :rules="model.rules.rel_mobile" :model="model" placeholder="Mobiel nummer" v-model="model.rel_mobile"></TextField>
            </FormRow>
            <FormRow label="Website">            
                <TextField max-length="128" :rules="model.rules.rel_website" :model="model" placeholder="Website" v-model="model.rel_website"></TextField>
            </FormRow>
            <FormRow label="Inkoop (crediteur)"> 
                <Checkbox switcher :model="model" v-model="model.use_in_purchase"></Checkbox>
            </FormRow>
            <FormRow label="Verkoop (debiteur)">            
                <Checkbox switcher :model="model" v-model="model.use_in_sales"></Checkbox>
            </FormRow>

        </template>
    </Form>
</template>



<script setup>

    import Form from '@controls/forms/Form'
    import FormColumn from '@controls/forms/FormColumn'
    import FormRow from '@controls/forms/FormRow'
    import TextField from '@controls/input/TextField'
    import CountryCombo from '@controls/combo/Country'
    import Checkbox from "@controls/check/Checkbox.vue";
    import eventbus from '@app/eventbus'
    import ActionButton from '@controls/buttons/ActionButton'
    import dlg from '@app/dlg'
    const props = defineProps({
        model: {
            type: [Object]
        },
    });

    async function onKVK() {
        var result = null;
        try {
            result = await dlg.promised.open('select_kvk', {});
        } catch (e) { return; } // canceled.
        if (result && result.length) {
            props.model.fromKvk(result[0])
        }
    }
</script>
