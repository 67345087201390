<template>
    <Dialog :dialog="dlg">
        <template v-slot:toolbar-left>
            <v-row dense>
                <v-col sm="auto">
                    <Icon size="m" class="bordered pa-2 mr-2 text-bold" type='phone'></Icon>
                </v-col>                
                <v-col>
                    <v-row dense>
                        <v-col class="pb-0 text-bold">Bel ons</v-col>
                    </v-row>
                    <v-row dense>
                        <v-col class="py-0">
                            <a href="tel:085861300">085 86 1300</a>
                        </v-col>
                    </v-row>
                </v-col>                
                <v-col sm="auto">
                    <Icon size="m" class="bordered pa-2 mr-2 text-bold" type='email'></Icon>
                </v-col>                
                <v-col>
                    <v-row dense>
                        <v-col class="pb-0 text-bold">Mail ons</v-col>
                    </v-row>
                    <v-row dense>
                        <v-col class="py-0">
                            <a href="mailto:support@optimit.nl">support@optimit.nl</a>
                        </v-col>
                    </v-row>
                </v-col>                
                
            </v-row>

        </template>

        <template v-slot:nonotabs-left>
            <v-tabs v-model="dlg.tab">
                <v-tab>Stel direct uw vraag </v-tab>
                <v-tab v-if="model.hasVideo">Videohandleidingen</v-tab>
            </v-tabs>
        </template>

        <v-tabs-items v-model="dlg.tab">
                    
                    <v-tab-item xclass="pl-4">
                        <v-form ref="form" lazy-validation>
                            <v-row dense>
                                <v-col class="heading-2">
                                    <h2>Stel direct uw vraag</h2>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col>                                    
                                    <TextField :model='model' :rules='model.rules.mandatory' v-model='model.email'></TextField>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col>
                                    <TextArea :rows="12" :model="model" dusk="question" placeholder="Stel uw vraag" v-model="model.question"></TextArea>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-tab-item>
                    <v-tab-item v-if="model.hasVideo">
                        <v-card flat>
                        <v-card-text>UHU!</v-card-text>
                        </v-card>
                    </v-tab-item>

        </v-tabs-items>

        <template v-slot:footer-post-actions>
            <ActionButton v-if="dlg.tab==0" dusk="btn-send" :disabled="dlg.isLoading||dlg.disabled" main primary ml @click="dlg.onSave()">
                Stuur
            </ActionButton>
        </template>

    </Dialog>
</template>



<script setup>
//     <div style="width:100%;min-height:600px;">
//         <iframe style="width:100%;min-height:600px;" class='ql-video' frameborder='0' allowfullscreen='true' src='https://www.youtube.com/embed/d4rU_ZzJihk'>
//         </iframe>
//     </div>
// - 
// <template v-slot:footer-left>
//     <a @click="dlg.tab=1" v-if="dlg.tab==0">
//         <Icon style="margin-top:-3px;" class="text-bold mr-1" color="error" type='play'></Icon> 
//         <span style="">Video-handleidingen</span>
//     </a>
//     <a @click="dlg.tab=0" v-else>
//         <Icon color="primary" type='arrowleft'></Icon> Terug
//     </a>
// </template>

    import useModel from '@/models/help'
    import TextArea from '@controls/input/TextArea'
    import Dialog from '@shared/ui/dialogs/ActionDialog'
    import { ref } from 'vue'
    import clsDialog from '@cls/clsDialog'
    import TextField from '@controls/input/TextField'
    import Row from '@controls/forms/Row'
    import Icon from '@controls/icons/Icon'
    import ActionButton from '@controls/buttons/ActionButton'
    const form = ref(null); 

    let model = useModel();
    let dlg = ref(
        new clsDialog("help", "Help", model, [form], 
            {
                // maxWidth:1100,
                noSubTitle: true,
                save:false,
                cancel:"Sluit",
            }
        )).value;

</script>
