import eventbus from '@app/eventbus'
import { onUnmounted } from 'vue'
import noty from '@lib/noty'
import dlg from '@app/dlg'
import {flags as apiFlag} from '@app/api'

/**
 * Basic class for generating the backbones for dialogs. 
 * Vue typically reuses the DOM as efficiently as possible. 
 * That is fine. But in case of dialogs, it means that once the dialog opened, and the components are mounted, the creation is finished.
 * When the dialog is closed, it is moved to shadow dom and when it is reopened, it is moved to DOM. 
 * This without going through the lifecycle again. 
 * 
 * In most cases this is perfectly fine, except when components used in the dialog must e.g. load new data on mount. 
 * Specificly, this is about datatable. 
 * When a dialog contains a datatable somewhere, e.g. addresses for a relation, the datatable is loaded once, and not again
 * when the dialog is reopened for another relation. This is problematic as wrong addresses are shown. 
 * 
 * In this case, you can force the dialog to go through all lifecycles by setting the 'setKeyOnOpen' property to true in the dialog 
 * construction options. 
 * 
 * Note that, at time of writing, no 'pagable' functionallity is there but when it is, probably also the key needs to be refreshed after each
 * page action for the same reason.  
 *    
 *********************************************************************************************
 * 
 * As mentioned, the dialog is designed to work with clsModel derived entities to automate most of the loading / saving and other 
 * model interaction. However, it can be used with simpler models too. 
 *
 * This is the minimum implementation of a dialog model: 
 * 
 *          class clsMinimumDialogModel {
 *             isLoading = false; // optional: 
 *       
 *             async load(id, params) {
 *                 console.log('id, params: ', id, params)
 *             }
 *             async save(bKeepOpen) {
 *                 this.isLoading = true;
 *                 console.log('saving')
 *                 this.isLoading = false;
 *             }
 *         }
 *      
 *  For an example, see DialogConfigureTable.vue
 */



class clsDialog {
    setKeyOnOpen= false;
    dlgKey      = undefined;
    modelName   = null
    model       = null;
    maxWidth    = "650px"
    _open        = false
    _tab         = 0
    _tabsTab     = 0 // When an onBeforeTabSwitch handler is used, this property is used to control the tabs index ... see tabsTab getter/setter
    onBeforeSwitchTab = null
    cancel      = "Annuleren"
    save        = "Opslaan"
    closeAfterSave = true
    applyOnNew  = false; // when it is a new model, keep it open after save. (apply i.s.o. ok)
    fnReject      = null
    fnResolve     = null
    keepOpen     = false
    // When tracking dirty state, we use the model to warn the user when closing without saving.
    trackDirtyState = true

    // A callback which is called when any model is saved when this dialog is open. 
    onModelSaved = null;
    onModelRemoved = null;

    cls         = "action-dialog"
    icon        = null
    dlgTitle    = "title"
    noSubTitle  = false
    showNote    = false
    noteType    = null // e.g. relation_note
    flagType    = null // e.g. salesinvoice_flag
    showAttachment  = false
    attachmentType = null // e.g. relation_attachment
    showFlag    = false
    canBrowse   = false
    forms=[];

    noActions = false
    diabled = false
    // Default focusfield. 
    // Usage:
    //      <control ref="q"></control>
    // ...
    //    const q = ref()
    // ...
    //   new clsDialog(....., {defaultFocus: ()=>q.value}) 
    defaultFocus = null 

    xl = 12;    // The columns dedicated to show the body in - xtra large device 
    lg = 12;    // The columns dedicated to show the body in - large device 
    sm = 12;    // The columns dedicated to show the body in - small device 

    // The tablename is retrieved when we are opened from a datatable.
    // It can be used to browse the data.
    tableName = null;

    get open() {
        return this._open;
    }
    set open(v) {
        if (this.model) {
            this.model.isDialogOpen = v;
        }
        this._open = v;
    }


    get tab() {
        return this._tab;
    }
    set tab(v) {
        this._tab = v;
        this._tabsTab = v;
    }
    get tabsTab() {
        return this._tabsTab;
    } 
    set tabsTab(v) {
        var tmp = this._tab;
        this._tabsTab = v;
        this._onBeforeSwitchTab(v).then ( (canProceed) => {
            if (!canProceed) {
                setTimeout( ()=>this._tabsTab = tmp, 100)
                return;
            } else {
                this._tab = v;
            }
        })
    }

    async _onBeforeSwitchTab(v) {
        if (this.onBeforeSwitchTab) {
            return await this.onBeforeSwitchTab(v);
        }
        return true;
    }

    setUniqueDialogKey() {
        if (!window._kdlg) {
            window._kdlg = 1;
        }
        window._kdlg++;
        this.dlgKey = window._kdlg + 1;;
    }
    /**
     * Reject this dialog. When we have a reject handler, call it so that processes waiting for the promise
     * get the rejected state.
     */
    _rejectDialog() {
        if (this.fnReject) {
            this.fnReject({canceled: true});
        }    
    }
    async closeDialog(force) {

        // When force close, just do it.
        if (force) {
            this.open = false;
            this._rejectDialog();
            return;
        }
        // If the dirty state is not tracked, just close.
        if (!this.trackDirtyState) {
            this.open = false; 
            this._rejectDialog();
            return;
        }
        // Do not stop closing a dialog when the model is disabled.
        if (this.model && this.model.disabled ) {
            this.open = false; 
            this._rejectDialog();
            return;
        }

        if (this.model.isModified) {
            try {
                await noty.confirm("Wilt u doorgaan?<br>De wijzigingen worden dan niet opgeslagen.", {title: "U heeft wijzigingen gemaakt.", labelConfirm: "Sluit scherm"});
            }
            catch(e) {
                return; // No. 
            }
        }
        this._rejectDialog();
        this.open = false;    
    }
    // Provide as a function option to execute ater after open
    onAfterOpenDialog() {

    }
    setDefaultFocus() {
        if (!this.defaultFocus || !(this.defaultFocus instanceof Function)) {
            return;
        }
        let focusCtrl = this.defaultFocus();
//        console.log('focusCtrl: ', focusCtrl);
        if (focusCtrl && focusCtrl.$el && focusCtrl.$el.getElementsByTagName) {
            var $el = focusCtrl.$el.getElementsByTagName('input');
            if ($el && $el.length && $el[0].focus) {
                $el[0].focus();
                // setTimeout( ()=>$el[0].focus() , 2009)
            }
        }
    }
    openDialog() {
        if (this.setKeyOnOpen) {
            this.setUniqueDialogKey();
        }
        this.open = true;
        this.resetValidation();
        if (this.onAfterOpenDialog) {
            this.onAfterOpenDialog();
        }
        // Remove the context for a short period as the reference to the field is probably not yet resolved.
        setTimeout( ()=> this.setDefaultFocus(), 100)
        // this.setDefaultFocus();
    }

    get attachmentCount() {
        if (!this.model) {
            return 0;
        }
        return this.model.attachmentCount;
    }
    get noteCount() {
        if (!this.model) {
            return 0;
        }
        return this.model.noteCount;
    }
    get flag() {
        if (!this.model) {
            return 0;
        }
        return this.model.flag;
    }
    set flag(value) {
        if (!this.model) {
            return;
        }
        this.model.flag = value;
    }
    // Set the flag value.
    // When the model is new, save it first.
    async setFlagValue (value) {
        if (this.model.isNew) {
            if (!await this.onSave(true)) {
                this.model.flag = null;
                return;
            }
        }

        let result = await apiFlag.save(value, this.model?.id_optimit_type, this.model?.id);
        this.model.flag = result.data.value;
        // Send a saved notification.
        var sendData = {id_entity: this.model?.id, flagType: this.flagType, flag: value};
        eventbus.model.saved('flag', sendData);
    }

    async onNote() {
        if (this.model.isNew) {
            if (!await this.onSave(true)) {
                return;
            }
        }
        try {
            var result = await dlg.promised.open('note', this.model, {rightsModelName: this.noteType}) 
            this.model.noteCount = (!!result.note) ? 1 : 0;
        } catch (e) {return; } // canceled.
    }
    async onAttachment() {
        if (this.model.isNew) {
            if (!await this.onSave(true)) {
                return;
            } 
        }
        try {
            var result = await dlg.promised.open('attachments', this.model, {rightsModelName: this.attachmentType}) 
            this.model.attachmentCount = result.count;
        } catch (e) {return; } // canceled.
    }

    get title() {
        if (this.model && this.model.title) {
            return this.model.title;
        }
        return this.dlgTitle || "";
    }

    get subTitle() {
        if (!this.model) {
            return "";
        }
        return this.model.subTitle;
    }

    get isLoading() {
        if (!this.model) {
            return false;
        }
        return this.model.isLoading;
    }

    get disabled() {
        if (!this.model) {
            return false;
        }
        return this.model.disabled;
    }

    setTab(n) {
        this.tab = n;
    }

    // Overrides for functions like 'onBulkRemove', etc
    callbacks= {

    }

    onKeyDown(evt, b, c) {
        if (evt && evt.key == "Escape") {
            this.onClose()
        }
        if (this.callbacks.onKeyDown) {
            this.callbacks.onKeyDown(evt)
        }
    }

    onClose() {
        if (this.callbacks.onClose) {
            this.callbacks.onClose()
        }
        else {
            this.closeDialog();
        }
    }

    // Called after load. Provide as an option to override.
    onAfterLoad(self) {

    }

    async onOpen(params, extraData) {
        // onBeforeOpen is called even before this method.
        params = params ||{};
        
        this.tableName = extraData?.tableName;

        if (this.model && this.model.load) {
            this.model.load(params.id, params, extraData)
            .then( () => {
                this.onAfterLoad(this);    
            })
            .catch( (e) => {
                console.error(e);
                this.closeDialog(); // model could not be loaded.
            })
        }
        this.openDialog();
        this.tab = 0;
    }
    // Reload the data in this dialog. 
    // Note, the model needs to use 'id' as key, which is standard for all models.
    reload() {
        return this.onOpen({id: this.model.id})
    }
    onCreate(params, extraData) {
        if (this.model && this.model.create) {
            this.model.create(params, extraData)
        }
        this.openDialog();
        this.tab = 0;
    }
    onCopy(params, extraData) {
        if (this.model && this.model.copy) {
            this.openDialog();
            this.tab = 0;            
            this.model.copy(params.id, params, extraData).catch( ()=> this.closeDialog(true) );
        }
    }

    /**
     * When forms are specified, reset their validation.
     */
    resetValidation() {
        for (var n = 0; n < (this.forms||[]).length; n++) {
            var form = this.forms[n]
            if (form && form.value) {
                form = form.value;
            }
            if (form.resetValidation) {
                form.resetValidation();
            }
        }
    }


    async validate() {
        for (var n = 0; n < (this.forms||[]).length; n++) {
            var form = this.forms[n]
            if (form && form.value) {
                form = form.value;
            }
            if (form && form.validate) {
                if (!form.validate()) {
                    this.setTab(n); 
                    return false;
                }
            }
        }

        if (this.model && this.model.validateBeforeSave) {
            var result = await this.model.validateBeforeSave();
            if (!result) {
                return false;
            }
        }

        return true;
    }

    // Override to execute actions after save;
    async onAfterSave() {}

    async onSave(bKeepOpen) {

        bKeepOpen = bKeepOpen || this.keepOpen;

        let modelIsNew = this.model.isNew;

        if (this.callbacks.onSave) {
            await this.callbacks.onSave()
            await this.onAfterSave()
            return;
        }
        var result = await this.validate();
        if (!result) {
            return false;
        }
        result = await this.model.save();
        await this.onAfterSave()
        if (this.fnResolve) {
            if (result && result.data) {
                result = result.data;
            }
            this.fnResolve(result);
        }

        if (bKeepOpen) {
            return result; // Do not close
        }

        // apply change on new file --> keep it open.
        if (modelIsNew && this.applyOnNew) {
            return result;
        }

        if (this.closeAfterSave) {
            
            this.closeDialog();
        }
        return result;
    }

    evtRegistrations = []
    registerEvents() {
        var self = this;

        this.evtRegistrations.push(
            eventbus.dialog.open.onPromised( async (fnResolve, fnReject, modelName, params, extraData) => {    
                if (modelName != self.modelName) {
                    return;
                }
                params = params ||{};
                if (this.model && this.model.onBeforeOpen) {
                    await this.model.onBeforeOpen(params, extraData);
                }
        
                self.onOpen(params, extraData);

                this.fnReject      = fnReject;
                this.fnResolve     = fnResolve;
            })
        )
        this.evtRegistrations.push(
            eventbus.dialog.open.on( async (modelName, params, extraData) => {
                if (modelName == self.modelName) {
                    if (this.model && this.model.onBeforeOpen) {
                        await this.model.onBeforeOpen(params, extraData);
                    }
    
                    self.onOpen(params, extraData);
                    this.fnReject      = null;
                    this.fnResolve     = null;
                }         
            })
        )
        this.evtRegistrations.push(
            eventbus.dialog.create.onPromised( (fnResolve, fnReject, modelName, params, extraData) => {    
                if (modelName != self.modelName) {
                    return;
                }
                self.onCreate(params, extraData);

                this.fnReject      = fnReject;
                this.fnResolve     = fnResolve;
            })
        )
        this.evtRegistrations.push(
            eventbus.dialog.create.on((modelName, params, extraData) => {
                if (modelName == self.modelName) {
                    self.onCreate(params, extraData);
                    this.fnReject      = null;
                    this.fnResolve     = null;
                }         
            })
        )
        this.evtRegistrations.push(
            eventbus.dialog.copy.on((modelName, params, extraData) => {
                if (modelName == self.modelName) {
                    self.onCopy(params, extraData);
                    this.fnReject      = null;
                    this.fnResolve     = null;
                }         
            })
        )
        this.evtRegistrations.push(
            eventbus.dialog.close.on((modelName, params, extraData) => {
                if (modelName == 'all' || (modelName == self.modelName)) {
                    self.onClose();
                }         
            })
        )
        this.evtRegistrations.push(
            eventbus.dialog.ga.on(() => {
                if (!self.open) {
                    return;
                }
//                console.log('_dlga ', self.modelName)
                window._dlga = self;
            })
        )
        // A dialog is the visual container in which a model operates on its data. 
        // Now, complex models need to register for save events of other data in order to keep data up to date. 
        // For example, the project dialog shows a project. The project has a result tab in which the project data 
        // is specified in costs, revenue and results. 
        // 
        // Now, also, the project dialog contains an invoice tab in which invoices connected to the project are shown.
        // The invoices for the project can be opened and manipulated. For example, they can be coupled to another project. 
        // When this happens, the result of the project change and hence, this data must be updated.
        // So, now the dilemma, we can catch the save event in the model - where it belongs, but, we don't want to refresh always. 
        // We only want to refresh when the project dialog is active. 
        // But, the model is not aware of whether it is visible or not. 
        // For this reason, we let the dialog listen to the model saved events, and pass this event to the model when it is active only. 
        // This means that the model can implement the onModelSaved method to handle those events. 
        // Also, when an onModelSaved method is added in the options on creation of the dialog object, this handler is called.
        // The reason is for further optimization. 
        // E.g. When updating invoices or purchase invoice from a project, it is a waste of resources to refresh the result data on every save. 
        // It is better to do this only when the result tab is activated.
        // 
        this.evtRegistrations.push(
            // The type and data parameter are meaningfull for us. The rest can be any custom piece of data.
            eventbus.model.saved.on((type, data, a,b,c) => {
                if (!self.open) {
                    return;
                }
                if (self.model) {
                    if (self.model.onModelSaved) {
                        self.model.onModelSaved(type, data, a,b,c); 
                    }    
                }
                if (self.onModelSaved) {
                    self.onModelSaved(type, data, a,b,c); 
                }

            })
        )
        this.evtRegistrations.push(
            // The type and data parameter are meaningfull for us. The rest can be any custom piece of data.
            eventbus.model.removed.on((type, data, a,b,c) => {
                if (!self.open) {
                    return;
                }
                if (self.model) {
                    if (self.model.onModelRemoved) {
                        self.model.onModelRemoved(type, data, a,b,c); 
                    }
                }
                if (self.onModelRemoved) {
                    self.onModelRemoved(type, data, a,b,c); 
                }

            })
        )

// When finished data loading, reset the form validation
//            if (this.model) {
//                watch(this.model.isDataLoading, function(n,o) {
//                    console.log('Yep. resetting validation.')
//                    self.resetValidation();
//                }) 
//            }
    }
    cleanUp() {
        (this.evtRegistrations||[]).forEach( (fnUnRegister) => fnUnRegister() ) 
    }

    /**
     * Same function also available in the browser component. 
     * Browse to next entity. 
     */
    browseNext() {
        var data = {tableName: this.tableName, id: this.model.id, isBrowsing: false}
        eventbus.datatable.browse.next(data);
    }
    
    /**
     * Set the focus to the first control in the last line in a set of lines. 
     * Usage: 
     *    A line is added and the first input must be selected. 
     *  
     * @param {*} evt     : required. The event which caused the addition. 
     * @param {*} clsLines: optional. The class for the lines section. Defaults to 'lines'
     * @param {*} clsInput: optional. When specified, an input control with this class is searched for.
     * @returns 
     */
    setFocusToFirstInputInLastLine(evt, clsLines, clsInput) {
        var fnSetFocus = (evt, clsLines, clsInput) => {
                if (!evt || !evt.target) {
                console.error("setFocus: no event target.");
                return;
            }
            var cls = `dialog-${this.modelName}`
            clsLines = clsLines || "lines";
            var dlg = evt.target.closest(`.${cls}`);
            if (!dlg) {
                console.error('setFocus: target lines not found');
                return;
            }
            var lines = dlg?.getElementsByClassName(clsLines)
                            ?.[0].getElementsByTagName('tbody')
                            ?.[0].getElementsByTagName('tr');        
            if (!lines || !lines.length) {
                console.error('setFocus: target line not found');
                return;
            }

            var lastLine = lines[lines.length-1];

            // if clsInput is specified, we want to focus in the control with that class. 
            if (clsInput) {
                var inp = lastLine.getElementsByClassName(clsInput);
                if (!inp) {
                    console.error("setFocus: target control not found");
                    return;
                }
                inp.focus();
                inp.select && inp.select();
                
                return;
            }

            // Ok. Well. When no target input class is specified, we need to select either the first input control, or the first ProseMirror control... 
            // That means, we need to loop through all td's. Otherwise we don't know which one comes first.
            var tds = lastLine.getElementsByTagName('td');
            for (var n = 0; n < tds.length; n++) {
                var td = tds[n];
                var inp = td.getElementsByTagName('input')?.[0] || td.getElementsByClassName('ProseMirror')?.[0];
                if (inp) {
                    inp.focus();
                    inp.select && inp.select();

                    return;
                }
            }
            console.error("setFocus: target control not available");
        }
        setTimeout(()=>fnSetFocus(evt, clsLines, clsInput), 10); // In a timeout. It makes sure current execution cycle is finished first
    }


    constructor(modelName, title, model, forms, options) {
        options = options||{}
        this.modelName = modelName;
        this.dlgTitle = title;
        this.forms = forms || [];
        var self = this;
        
        this.model = model;
        this.registerEvents();
        this.setUniqueDialogKey();
        this.applyOnNew = false;        
        this.canBrowse = options.canBrowse;

        for (var key in options) {
            this[key] = options[key];
        }
        this.noteType = options.noteType || null; 
        this.flagType = options.flagType || null; 
        this.attachment = options.attachment || null; 
        this.attachmentType = options.attachmentType || null
        
        this.onModelSaved = options.onModelSaved || null;
        this.onModelRemoved = options.onModelRemoved || null;

        if (!this.icon && this.modelName && !options.noIcon) {
            this.icon = this.modelName;
        }

        this.cls = `${this.cls} dialog-${this.modelName}`
        
        onUnmounted(() => {
            self.cleanUp();
        })
    }
}

export default clsDialog;