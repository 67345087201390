<template>    

    <v-text-field v-if="noAutoComplete" 
        :type="showClearText ? 'text' : 'password'"
        class="password"
        autocomplete="off"
        :value="dvalue"
        @input="dvalue = $event"
        @keyup.enter="onEnter"
        hide-details 
        :disabled="disabled"
        :dense="dense" 
        outlined
        :rules="rules"
        :placeholder="cPlaceholder"        
        :label="label"
        :prepend-inner-icon="prependInnerIcon"
        :dusk="dusk"
        >
            <template v-slot:append>                
                <span class="cursor-pointer mt-1" @click="showClearText = !disabled && !showClearText"><Icon small :type="showClearText ? 'show' : 'hide'"></Icon></span>
            </template>

    </v-text-field>

    <v-text-field v-else
        :type="showClearText ? 'text' : 'password'"
        class="password"
        autocomplete="current-password"
        :value="dvalue"
        @input="dvalue = $event"
        @keyup.enter="onEnter"
        hide-details 
        :disabled="disabled"
        :dense="dense" 
        outlined
        :rules="rules"
        :placeholder="cPlaceholder"        
        :label="label"
        :prepend-inner-icon="prependInnerIcon"
        :dusk="dusk"
        >
            <template v-slot:append>                
                <span class="cursor-pointer" @click="showClearText = !disabled && !showClearText"><Icon :type="showClearText ? 'show' : 'hide'"></Icon></span>
            </template>

    </v-text-field>

</template>

<script setup>
import {computed, ref} from 'vue'
import Icon from '@controls/icons/Icon'

//  import PasswordField from '@controls/input/PasswordField'
//
// Usage: 
//   <PasswordField v-model="password"></PasswordField>
//

let showClearText = ref(false)

const props = defineProps({
    value: {
        type: [String, Number]
    },
    dusk: {
        type: [String, Number]
    },
    disabled: {
        type: [Boolean]
    },
    noAutoComplete: {
        type: [Boolean],
        default: true,
    },
    dense: {
        type: [Boolean],
        default: true,
    },
    innerIcon: {
        type: [Boolean],
        default: false,
    },
    placeholder: {
        type: [String]
    },
    rules: {
        type: [Array]
    },
    label: {
        type: [String]
    },
    prependInnerIcon: {
        type: [String]
    },
});

const emit = defineEmits(['input', 'enter'])

const dvalue = computed({
    get() {
        return props.value
    },
    set: function(v) {
        emit('input', v)
    }	
})

const cPlaceholder = computed({
    get() {
        if (props.label) {
            return null;
        }
        return props.placeholder;
    },
})
function clear() {
    dvalue = null;
}
function onEnter() {
    emit('enter');              
}

</script>




