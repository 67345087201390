<template>

    <Picker :useMenu="!!valueModel[idField]"
        :disabled="disabled"
        :skeleton="skeleton"
        :rules="rules"
        :noClearable="noClearable"
        :placeholder="placeholder"
        :model="model"         
        :valueModel="valueModel"
        :filter="filter"
        :idField="idField" 
        :idParent="idParent" 
        :repField="repField"
        selectIdField="id" 
        selectRepField="mdr_number" 
        :fieldMap="fieldMap"
        dialogName="select_manday"
        :noBorder="noBorder"
        :propagateClear="propagateClear"
        @select="onSelect"
    >
    <template v-slot:menu="{id,clear,disabled, search}">
        <ActionMenuItem v-if="id" icon="search" @click="()=>search()">Zoek andere mandagenstaat</ActionMenuItem>
        <ActionMenuItem v-if="id" icon="open" @click="()=>dlg.open('manday', {id: id})">Open mandagenstaat</ActionMenuItem>
        <ActionMenuItem v-if="id" icon="pdf" @click="()=>onPdfDownload(id)">Download PDF</ActionMenuItem>
        <ActionMenuItem icon="remove" @click="()=>clear()">Maak leeg</ActionMenuItem>
    </template>


</Picker>

</template>

<script setup>
    import dlg from '@app/dlg'
    import ActionMenuItem from "@controls/menu/ActionMenuItem.vue";        
    // import MandayPicker from '@controls/picker/Manday'
    // <MandayPicker :model="model" :rules="model.rules.id_mandays_registration" v-model="model.id_mandays_registration"></MandayPicker>

    const emit = defineEmits(['select'])
    function onSelect(v) { emit('select', v); }

    const props = defineProps({

        disabled: {
            type: [Boolean]
        },
        rules: {
            type: [Array]
        },
        noClearable: {
            type: [Boolean],
            default: false
        },
        noBorder: {
            type: [Boolean]
        },
        skeleton: {
            type: [Boolean]
        },
        placeholder: {
            type: [String],
            default: 'Manurenstaat'
        },
        model: {
            type: [Object]
        },
        valueModel: {
            type: [Object]
        },
        // Mandatory. The name of the field in the valueModel or model which is the id of this picker field
        idField: {
            type: [String],
            default: 'id_mandays_registration'            
        },
        // Mandatory. The name of the field in the valueModel or model which is the representation of this picker field
        repField: {
            type: [String],
            default: 'mdr_number'            
        },
        // Optional. The id in the select list which we take over on select
        selectIdField: {
            type: [String],
            default: "id"
        },
        // Optional. The repField in the select list which we take over on select. Defaults to the property 'repField'
        selectRepField: {
            type: [String],
        },
        // When provided, the fieldmap contains fields which must be taken over from a selected item.
        // Those are sibling fields. For example, for a project picker, the reference may be displayed in a separate field or column.
        fieldMap: {
            type: [Object]
        },
        // When the field is filled, and then cleared, should the new values (empty value) be propagated to
        // any depending field? (defined in the fieldmap)
        propagateClear: {                    
            type: [Boolean]
        },
        // The default filter used for browsing. 
        // Example, for selecting a project in a purchase invoice, only projects which are in phase where it is allowed to 
        // book purchase invoices should be found. The same for sales invoices. 
        filter: {
            type: [Object]
        },
        // When specified, the parent id is sent along with the query.
        idParent: {
            type: [Number, String],
            default: null
        },
    })
    
    import Picker from '@controls/picker/Picker'

    import { mandays as mandayApi } from '@app/api'
    async function onPdfDownload(id) {

        var rep = "";
        if (props.valueModel) { 
            rep = props.valueModel[props.repField]
        } else if(props.model) { 
            rep = props.model[props.repField]
        }                       
        rep = rep || "";        

        var filename = `Mandagstaat ${rep}`.replaceAll('/', '-').replaceAll(" - ", "-");
        await mandayApi.downloadPostRaw("examplepdf", {id: id}, null, filename);    
    }


</script>
