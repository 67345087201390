<template>    
    <v-menu
        v-model="open"
        :close-on-content-click="false"        
        transition="scale-transition"
        offset-y
        min-width="290px"
    >
        <template v-slot:activator="{ on }">
            <span :class="disabled?'':'cursor-pointer text-primary'" @click="open = (disabled||skeleton)?false:!open"> 
                <slot name="prepend"></slot>
                <Icon style="margin-top:-1px;" :color="disabled?'':'primary'" class="ml-2 xmt-1 xmr-2" small type="calendar"></Icon>
            </span>
        </template>
        <v-date-picker first-day-of-week="1" no-title show-week v-model="dvalue" @input="open = false">
            <v-spacer v-if="canClear"></v-spacer>         
                <v-btn v-if="canClear" class="text-center" @click="onClear" text small color="primary">Maak veld leeg</v-btn>                
            <v-spacer></v-spacer>
        </v-date-picker>
    </v-menu>
</template>

<script setup>

// Usage: 
// import DateButton from '@controls/buttons/DateButton'
// <DateButton v-model="date"></DateButton>

import {date} from '@lib/date'
import Icon from '@controls/icons/Icon'

import {computed, ref} from 'vue'

    var open = ref(false);
        
    const props = defineProps({
        disabled: {
            type: [Boolean]
        },
        canClear: {
            type: [Boolean],
            default: false
        },
        skeleton: {
            type: [Boolean]
        },
        value: {
            type: [String, Number, Date]
        },
        color: {
            type: [String]
        },
    })

    const emit = defineEmits(['input'])
    const dvalue = computed({
        get() {
            return date.fmt.iso(props.value)
        },
        set: function(v) {
            emit('input', v)
        }	
    })
    function onClear() {
//        dvalue = null;
//        open = false;
    }
</script>
