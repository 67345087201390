<template>
        <v-card class="sales-inoice-totals bg-card mb-2">

            <v-card-text>
                <v-row dense>
                    <v-col class="pr-4">  
                        <div v-if="model.useCustomFooter">
                           <RichTextArea :disabled="model.isOpenOrLater" xs :model="model" placeholder="Voet tekst" v-model="model.inv_comment">
                            <template v-slot:toolbar-cmd-right="{editor}">
                                <ActionButton :disabled="model.isOpenOrLater" link-button class="ml-4" action="salesinvoice.modify" dusk="btn-default-footer-text" :model="model" @click='()=>model.setDefaultFooterText()'>Reset tekst</ActionButton>
                            </template>
                            <template v-slot:toolbar-right="{editor}">
                                <ActionButton :disabled="model.isOpenOrLater" icon="text" class="mr-2" sub action="salesinvoice.modify" dusk="btn-select-footer-text" :model="model" ml @click='()=>addStandardText(editor)'>Standaard Tekst</ActionButton>
                            </template>
                           </RichTextArea>
                        </div>          
                    </v-col>
                    <v-col class="col-auto pl-12 pr-0">
                        <v-row dense class="text-bold text">
                            <v-col class="label no-wrap">Totaal excl. BTW</v-col>                                    
                            <v-col class="px-4"></v-col>                                    
                            <v-col class="amount no-wrap text-right"><AmountText v-model='model.totalExcl'/></v-col>                                    
                        </v-row>
                        <v-row dense v-for="(vatline, i) in model.vatLines" :key="i">
                            <v-col class="label text-small text-secondary no-wrap">{{vatline.name}} over <AmountText v-model='vatline.lineAmountExclVat'/></v-col>                                    
                            <v-col class="px-4"></v-col>                                    
                            <v-col class="amount text-small text-secondary no-wrap text-right"><AmountText v-model='vatline.vat'/></v-col>                                    
                        </v-row>
                        <v-divider class="my-2" ></v-divider>
                        <v-row dense class="text-bold text-large text mb-4">
                            <v-col class="label no-wrap">Totaal incl. BTW</v-col>                                    
                            <v-col class="px-4"></v-col>                                    
                            <v-col class="amount no-wrap text-large text-right"><AmountText v-model='model.totalIncl'/></v-col>                                    
                        </v-row>

                        <v-row dense class="text-bold" v-if="feature.canGAccount&&!model.isCreditInvoice">
                            <v-col class="no-wrap">
                                <ActionMenu left offset-x nudge-left="10" nudge-bottom="30" xbottom class="mt-8" :closeOnContentClick="!true">
                                    <ActionButton link-button icon='edit'>Naar G-rekening</ActionButton>
                                    <template v-slot:dropmenu>
                                        <v-card>
                                            <v-card-title flat class="no-wrap pt-0 pb-0">
                                                <v-list class="dialog-header-title">
                                                    <v-list-item class="pa-0 no-hover">
                                                        <v-list-item-content>
                                                            <v-list-item-title >
                                                                <h1>Gebruik G-rekening</h1>
                                                            </v-list-item-title>
                                                            <v-list-item-subtitle>
                                                                <caption>Een gedeelte van de factuur moet naar de G-rekening worden overgemaakt</caption>
                                                            </v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </v-list-item>                                    
                                                </v-list>
                                                <v-spacer></v-spacer>                            
                                                <span class="xcommands ">                                        
                                                    <Checkbox :disabled="model.isOpenOrLater" switcher :model='model' v-model='model.inv_use_g_account'></Checkbox>
                                                </span>
                                            </v-card-title>
                                            <v-card-text flat class="pa-0">
                                                <v-divider class="mb-0"></v-divider>
                                            </v-card-text>

                                            <v-card-text>
                                                <FormRow col-label="4" label="G-rekening">            
                                                    <ToggleButtonGroup :disabled="model.isOpenOrLater||!model.inv_use_g_account" col-auto :model="model" v-model="model.inv_g_account_type" :options="gAccountType"/>
                                                </FormRow>
                                                <FormRow v-if="model.inv_g_account_type == 'percentage'" col-label="4" label="Loondeel">    
                                                    <NumberField :disabled="model.isOpenOrLater||!model.inv_use_g_account" :model="model" amount v-model="model.inv_wage"></NumberField>
                                                </FormRow>
                                                <FormRow v-if="model.inv_g_account_type == 'percentage'" col-label="4" label="G-rekening percentage">    
                                                    <NumberField decimals="0" :disabled="model.isOpenOrLater||!model.inv_use_g_account" :model="model" percentage v-model="model.inv_g_account_perc"></NumberField>
                                                </FormRow>
                                                <FormRow col-label="4" label="Naar G-rekening">    
                                                    <NumberField :disabled="model.isOpenOrLater||!model.inv_use_g_account||(model.inv_g_account_type != 'amount')" :model="model" amount v-model="model.inv_g_amount"></NumberField>
                                                </FormRow>

                                            </v-card-text>
                                        </v-card>
                                    </template>
                                </ActionMenu>
                            
                            </v-col>                                    
                            <v-col class="px-4"></v-col>                                    
                            <v-col class="no-wrap text-right">
                                <AmountText v-if="model.inv_use_g_account" v-model='model.inv_g_amount'/>
                                <span v-else>€ 0,00</span>
                            </v-col>
                        </v-row>
                        <v-row v-if="feature.canGAccount && model.inv_use_g_account" dense class="text-bold">
                            <v-col class="no-wrap">Naar Bankrekening</v-col>                                    
                            <v-col class="px-4"></v-col>                                    
                            <v-col class="no-wrap text-right"><AmountText v-model='model.noGAmount'/></v-col>                                    
                        </v-row>

                    </v-col>
                </v-row>
                
            </v-card-text>
        </v-card>
</template>



<script setup>

    import Constants from '@app/consts'
    import Icon from '@controls/icons/Icon'
    import AmountText from '@controls/text/Amount.vue'
    import ActionMenu from '@controls/menu/ActionMenu.vue'
    import Checkbox from '@controls/check/Checkbox.vue'
    import FormRow from '@controls/forms/FormRow'
    import NumberField from '@controls/input/NumberField'
    import ToggleButtonGroup from '@controls/buttons/ToggleButtonGroup'
    import ActionButton from '@controls/buttons/ActionButton'
    import RichTextArea from '@controls/input/RichTextArea'
    import feature from "@app/features";
    import {watch, computed} from 'vue'
    import dlg from '@app/dlg'

    let gAccountType = [{id: 'percentage', name: 'Percentage'}, {id: 'amount', name: 'Vast bedrag'}];

    const props = defineProps({
        model: {
            type: [Object]
        },
    });
    
    const compTotalAmount = computed({
        get() {
            return props.model.totalIncl ?? 0;
        }
    })
    const compUseG = computed({
        get() {
            return props.model.inv_use_g_account ? 1 : 0;
        }
    })

    watch(compTotalAmount, (newValue, oldValue) => {
        props.model.onTotalChanged(newValue, oldValue);
    })
    watch(compUseG, (newValue, oldValue) => {
        props.model.onUseGChanged(newValue, oldValue);
    })

    async function addStandardText(editor) {
        let selected = null;
        try { 
            selected = await dlg.promised.open("select_standardtext", {multiselect:false})
            if (!selected || !selected.length) {
                return;
            }
        } catch (e) { return; }  // canceled
        if (selected && selected.length && selected[0].text) {
            editor.chain().focus().insertContent(selected[0].text).run()
        }
    }

</script>
