import clsConst from '@cls/clsConst'
import rights from './const/rights.js'
import vat from '@lib/vat'
import modules from './const/modules.js'

// 
// Usage: import Constants from '@app/consts'
// 
class clsConstExt extends clsConst {

    modules= modules;
    rights = rights


    // Priority is the default priority list - low normal high.
    priority = {
        HIGH: 1,
        NORMAL: 2,
        LOW: 3,
    }

    limits = {
        file_upload: {
            bytes: 10485760, 
            text:  '10Mb'
        } 
    }
    widgets = {
        ID_WIDGET_REVENUE      : 10,
        ID_WIDGET_COST         : 11,    
        ID_WIDGET_INVOICES_OPEN: 12,
        ID_WIDGET_TODO         : 13,
    }

    accountancy= {
        TYPE_CASH         : 1,
        TYPE_EXACT        : 2,
        TYPE_TWINFIELD    : 3,
        TYPE_UNIT4        : 4,
        TYPE_ACCOUNTVIEW  : 5,
        TYPE_YUKI         : 6,
        TYPE_MUIS         : 7,
    }


    // Identification of entity types within optimit. 
    // Used by e.g. the notes and attachments functionallity.
    optimit_types = {

        relation:         1,
        salesinvoice:     2,
        purchaseinvoice:  3,
        manday:           4,
        project:          5,
        workorder:        6,
        tender:           7,
        employee:         8,
        periodicinvoice:  9,
        paymentorder:     10,

        company:          11,
        product:          12,
        supplier:         13,
        tariff:           14,
        rightsgroup:      15,
        vendor:           16,    
        jobtitle:         17,    
        user:             18,
        costtype:         19,       
        dispute:          20,       
        salesorder:       21, // 
        emailjob:         22,       
        workordertype:    23,       
        hours:            24,
        incasso_mandate:  25,
    
    }

    themes = {
        standard: 1, 
        simple: 2,         
        modern: 3, 
        business: 4, 
    }

    // Some obvious defaults.
    defaults = {
        country_code: 'NL',
        id_language: 1,             // NL. 
        id_vat: vat.ID_VAT_HIGH,    // 
    }
    
    email = {
        types: {
            ID_EMAIL_TYPE_INVOICE                   : 100,  
            ID_EMAIL_TYPE_INVOICE_CREDIT            : 110,  
            ID_EMAIL_TYPE_INVOICE_REMINDER1         : 120,  
            ID_EMAIL_TYPE_INVOICE_REMINDER2         : 130,  
            ID_EMAIL_TYPE_INVOICE_DEFAULTNOTICE     : 140,  
            ID_EMAIL_TYPE_INVOICE_REMINDER          : 150,  
            ID_EMAIL_TYPE_TENDER                    : 200,  
            ID_EMAIL_TYPE_TENDER_CONFIRMATION       : 210,  
            ID_EMAIL_TYPE_MANDAYREGISTRATION        : 300,              
        }
    } 
    workorder = {
        status: {
            CONCEPT:    "concept",
            PLANNED:    "planned", 
            DONE:       "done",        
        },
    }

    tender = {
        status: {
            STATUS_CONCEPT    :10,
            STATUS_OPEN       :20,
            STATUS_ACCEPTED   :30,
            STATUS_REJECTED   :40,
            STATUS_FINISHED   :"archived", // This is actually a fake status. Finished tenders are the archived tenders.        
        },
        variables: [
            { label: "Factuurnummer",             "value": "%OFFERTENUMMER%" },
            { label: "Factuurreferentie",         "value": "%REFEROFFERTEREFERENTIEENTIE%" },
        ]
    }

    manday = {
        variables: [
            { label: "Weeknummer",   "value": "%WEEK%" },
            { label: "Jaar",         "value": "%JAAR%" },
        ]
    }


    invoice = {

        reminder: {
            REMINDER1 :1,
            REMINDER2 :2,
            DEFAULTNOTICE :3,
        }, 
        status: {
            NEW:        "new", 
            CONCEPT:    "concept",
            OPEN:       "open", 
            PAID:       "paid",        
        },


        // The variables which can be used in footers and email texts
        variables: [
                { label: "Factuurnummer",             "value": "%FACTUURNUMMER%" },
                { label: "Factuurreferentie",         "value": "%REFERENTIE%" },
                { label: "Factuurdatum",              "value": "%FACTUURDATUM%" },
                { label: "Vervaldatum",               "value": "%VERVALDATUM%" },
                { label: "Factuurbedrag",             "value": "%FACTUURBEDRAG%" },
                { label: "Al betaald",                "value": "%BEDRAGBETAALD%" },
                { label: "Nog te betalen",            "value": "%BEDRAGOPEN%" },
                { label: "Betaaltermijn (dagen)",     "value": "%BETAALTERMIJN%" },
                { label: "Uw naam",                   "value": "%UW_NAAM%" },
                { label: "Bankrekening",              "value": "%BANKREKENING%" },
                { label: "G-Rekening",                "value": "%G-REKENING%" },
                // { label: "Factuur details",           "value": "%INVOICEINFOBLOCK%" },
        ],
        credit_variables: [
            { label: "Factuurnummer",             "value": "%FACTUURNUMMER%" },
            { label: "Factuurreferentie",         "value": "%REFERENTIE%" },
            { label: "Factuurdatum",              "value": "%FACTUURDATUM%" },
            { label: "Vervaldatum",               "value": "%VERVALDATUM%" },
            { label: "Factuurbedrag",             "value": "%FACTUURBEDRAG%" },
            { label: "Te crediteren bedrag",      "value": "%CREDITDEEL%" },
            { label: "Al betaald",                "value": "%BEDRAGBETAALD%" },
            { label: "Nog te betalen",            "value": "%BEDRAGOPEN%" },
            { label: "Betaaltermijn (dagen)",     "value": "%BETAALTERMIJN%" },
            { label: "Uw naam",                   "value": "%UW_NAAM%" },
            { label: "Bankrekening",              "value": "%BANKREKENING%" },
            { label: "G-Rekening",                "value": "%G-REKENING%" },
            // { label: "Factuur details",           "value": "%INVOICEINFOBLOCK%" },
        ],
        g_variables: [
            { label: "Factuurnummer",             "value": "%FACTUURNUMMER%" },
            { label: "Factuurreferentie",         "value": "%REFERENTIE%" },
            { label: "Factuurdatum",              "value": "%FACTUURDATUM%" },
            { label: "Vervaldatum",               "value": "%VERVALDATUM%" },
            { label: "Factuurbedrag",             "value": "%FACTUURBEDRAG%" },
            { label: "Al betaald",                "value": "%BEDRAGBETAALD%" },
            { label: "Nog te betalen",            "value": "%BEDRAGOPEN%" },
            { label: "Betaaltermijn (dagen)",     "value": "%BETAALTERMIJN%" },
            { label: "Uw naam",                   "value": "%UW_NAAM%" },
            { label: "Bankrekening",              "value": "%BANKREKENING%" },
            { label: "G-Rekening",                "value": "%G-REKENING%" },
            { label: "Loondeel",                  "value": "%LOONDEEL%" },
            { label: "G-percentage",              "value": "%SOCIAALPERCENTAGE%" },
            { label: "G-bedrag",                  "value": "%SOCIAALDEEL%" },
            { label: "Restbedrag na G-bedrag",    "value": "%RESTDEEL%" },
        ]        

    }
    purchaseinvoice = {
        logtypes: {
            ID_LOGTYPE_UPLOADED                   :  10,    //  'Nieuw factuur geupload'
            ID_LOGTYPE_RECEIVED                   :  20,    //  'Nieuw factuur via email'
            ID_LOGTYPE_SAVED                      :  25,    //  'Factuur opgeslagen'
            ID_LOGTYPE_NEW                        :  27,    //  'Factuur terug naar status nieuw'
            ID_LOGTYPE_ACCEPTED                   :  30,    //  'Factuur geaccepteerd'
            ID_LOGTYPE_REJECTED                   :  40,    //  'Factuur geweigerd'
            ID_LOGTYPE_IN_PAYMENTORDER            :  50,    //  'Factuur toegevoegd aan betaalopdracht'
            ID_LOGTYPE_REMOVED_FROM_PAYMENTORDER  :  60,    //  'Factuur verwijderd uit betaalopdracht'
            ID_LOGTYPE_SENT_TO_ACCOUNTANCY        :  70,    //  'Factuur naar boekhouding gestuurd'
            ID_LOGTYPE_ENRICHMENT_STARTED         :  80,    //  'Factuur verrijking gestart'
            ID_LOGTYPE_ENRICHMENT_FINISHED        :  90,    //  'Factuur verrijking geeindigd'
            ID_LOGTYPE_ENRICHMENT_FAILED          :  100,   //  'Factuur verrijking mislukt'
            ID_LOGTYPE_DISPUTE                    :  110,   //  'Factuur status dispuut'
            ID_LOGTYPE_REMOVED_DISPUTE            :  120,   //  'Factuur dispuut verwijderd'
            ID_LOGTYPE_MARKED_AS_PAID             :  130,   //  'Factuur is gemarkeerd als betaald'
            ID_LOGTYPE_MARKED_AS_NOT_PAID         :  140,   //  'Factuur is gemarkeerd als niet betaald'
            ID_LOGTYPE_PAYMENTORDER_IS_PAID       :  150,   //  'Betaalopdracht is betaald'
        }    
    }

} 

export default clsConstExt;
