import clsSettings from '@cls/clsSettings'
import feature from '@app/features'

var modelName="salesinvoice"
class clsInvoiceSettings extends clsSettings {

    /**
     * clone the current setting.
     * load fresh data from the server.
     * autoLoad: load the data automagically
     * noDestroy: prevent object being cleaned up on unmount.
     *            Actually, can not think for a use case.
     */
    clone(autoLoad, noDestroy) {
        var obj = new clsInvoiceSettings();

        if (!noDestroy) {
            obj.registerDestroyOnUnmount();            
        }
        if (autoLoad) {
            obj.load();
        }
        return obj;
    }


    show_info_duedate = null
    show_info_relation_vat = null
    show_info_project_nr = null
    show_info_project_ref = null
    show_info_reference = null
    show_info_relation_nr = null
    show_col_heading = null
    show_col_count = null
    show_col_unit = null
    show_col_unitprice = null
    show_col_discount = null
    show_col_vat = null
    line_total_price = null
    footer_invoice = null
    footer_credit_invoice = null
    footer_g_account = null
    email_copy = null
    email_copy_send = null
    payment_duedays = null
    next_number = null
    send_ubl = null
    use_bookdate = null
    use_consumer_prices = false
    incl_excl_vat = null
    footer_align = null
    use_custom_footer = null
    defaults = {};

    constructor() {
        super({modelName: modelName});
    }
    
    fill(data) {
        if (!feature.canProject) {
            data.show_info_project_ref  = false;
            data.show_info_project_nr  = false;
        }
        this.show_info_duedate = data.show_info_duedate;
        this.show_info_relation_vat = data.show_info_relation_vat;
        this.show_info_project_nr = data.show_info_project_nr;
        this.show_info_project_ref = data.show_info_project_ref;
        this.show_info_reference = data.show_info_reference;
        this.show_info_relation_nr = data.show_info_relation_nr;
        this.show_col_heading = data.show_col_heading;
        this.show_col_count = data.show_col_count;
        this.show_col_unit = data.show_col_unit;
        this.show_col_unitprice = data.show_col_unitprice;
        this.show_col_discount = data.show_col_discount;
        this.show_col_vat = data.show_col_vat;
        this.line_total_price = data.line_total_price || "auto"
        this.footer_invoice = data.footer_invoice;
        this.footer_credit_invoice = data.footer_credit_invoice;
        this.footer_g_account = data.footer_g_account;
        this.email_copy = data.email_copy;
        this.email_copy_send = data.email_copy_send;
        this.payment_duedays = data.payment_duedays;
        this.next_number = data.next_number;
        this.send_ubl = data.send_ubl;
        this.use_bookdate = data.use_bookdate;
        this.incl_excl_vat = data.incl_excl_vat;
        this.use_consumer_prices = !!data.use_consumer_prices
        this.footer_align = data.footer_align;
        this.use_custom_footer = !!data.use_custom_footer;
        this.defaults = data.defaults;
    }

    toJSON() {
        return {
            show_info_duedate: this.show_info_duedate,
            show_info_relation_vat: this.show_info_relation_vat,
            show_info_project_nr: this.show_info_project_nr,
            show_info_project_ref: this.show_info_project_ref,
            show_info_reference: this.show_info_reference,
            show_info_relation_nr: this.show_info_relation_nr,
            show_col_heading: this.show_col_heading,
            show_col_count: this.show_col_count,
            show_col_unit: this.show_col_unit,
            show_col_unitprice: this.show_col_unitprice,
            show_col_discount: this.show_col_discount,
            show_col_vat: this.show_col_vat,
            line_total_price: this.line_total_price || "auto",
            use_consumer_prices: this.use_consumer_prices,
            footer_invoice: this.footer_invoice,
            footer_credit_invoice: this.footer_credit_invoice,
            footer_g_account: this.footer_g_account,
            email_copy: this.email_copy,
            email_copy_send: this.email_copy_send,
            payment_duedays: this.payment_duedays,
            next_number: this.next_number,
            send_ubl: this.send_ubl,
            use_bookdate: this.use_bookdate,
            incl_excl_vat: this.incl_excl_vat,         
            footer_align: this.footer_align,
            use_custom_footer:this.use_custom_footer,

            defaults: this.defaults,
   
        };
    }

}

export default clsInvoiceSettings;
