<template>
    <v-simple-table dense class="header-contrast footer-contrast no-hover no-bordered no-border-bottom border-header-row-bottom">
        <thead>
            <th class="pl-4 pa-2 text-left">Uren overzicht</th>
            <th class="pa-2 w-3_5 text-right">Uren</th>
            <th class="pa-2 w-3_5 text-right">Kosten</th>
            <th class="col-contrast w-0_5 px-2"></th>
        </thead>
        <tbody>
            <tr v-if="!model.chapterResult.hoursPerEmployee.length" class="opacity-50">
                <td colspan="3">Geen uren geregistreerd</td>
            </tr>

            <tr v-for='(line, index) in model.chapterResult.hoursPerEmployee' :key='`hpe-${index}`'>
                <td class="text-primary">{{ line.name }}</td>
                <td class="pa-2 text-right col-contrast">
                    {{line.hours }}
                </td>
                <td class="pa-2 text-right col-contrast">
                    <AmountText leading-min :value='line.amount'/>    
                </td>
                <td :class="clsColIcon">
                    <v-menu v-if="line.warnings.length" :close-on-content-click="false" :nxudge-width="200" offset-x >
                        <template v-slot:activator="{ on, attrs }">
                            <span class="cursor-pointer " v-on="on"><Icon type='tip' :color="line.warnings.length?'error':'primary'"></Icon></span>
                        </template>
                        <v-card>
                            <v-card-title>
                                Uren {{ line.name }}
                            </v-card-title>
                            <v-divider v-if="line.warnings.length">
                            </v-divider>
                                <v-card-text v-if="line.warnings.length">
                                <UlCustom v-if="line.warnings.length">
                                    <LiCustom warning v-for='(warning, index) in line.warnings' :key="`hpe_warning_${index}`">{{ warning }}</LiCustom>    
                                </UlCustom>
                            </v-card-text>
                        </v-card>
                    </v-menu>
                </td>
            </tr>          
        </tbody>
        <tfoot>
            <tr>
                <td class="pl-4 pa-2 text-left text-bold">Totaal</td>
                <td class="pa-2 text-right text-bold">
                    {{model.chapterResult.hoursPerEmployeeTotals.hours }} 
                </td>
                <td class="pa-2 text-right text-bold">
                    <AmountText leading-min :value='model.chapterResult.hoursPerEmployeeTotals.amount'/>    
                </td>
                <td :class="clsColIcon"></td>
            </tr>
        </tfoot>

    </v-simple-table>
</template>

<script setup>

    import AmountText from '@controls/text/Amount.vue'

    import Icon from '@controls/icons/Icon'

    import UlCustom from "@controls/list/UlCustom.vue";
    import LiCustom from "@controls/list/LiCustom.vue";

    const clsColIcon="col-contrast px-2";

    const props = defineProps({
        model: {
            type: [Object]
        },
    });



</script>