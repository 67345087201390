<template>
    <Dialog :dialog="dlg" no-content-padding>
        <v-list dense class="y-draggable">

            <draggable v-model="model.headers" handle=".draghandle" ghost-class="ghost" animation="200">
                <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                <template v-for="(line, index) in model.headers">
                    <v-list-item  v-if="!(!line.canhide&&!line.canmove)" :key="index" dense class="draghandle xmb-2 cursor-move-y">
                        <v-list-item-icon class="xdraghandle cursor-move-y mr-4">
                            <Icon small type="drag"></Icon>
                        </v-list-item-icon>

                        <v-list-item-content dense class="mt-0 mb-0 py-0 ">
                            <Checkbox :disabled="false===line.canhide" v-model="line.visible" :label="line.configText||line.text"></Checkbox>
                            <span v-if="!model.noSort" style="position:absolute;right:16px;" class="ml-4 cursor-pointer sort-order" @click="()=>model.onSortBy(line)">
                                <span v-if="line.sort == 'desc'">
                                    <Icon small type="sort-down"></Icon>
                                </span>
                                <span v-else-if="line.sort && (line.sort != 'desc')">
                                    <Icon small type="sort-up"></Icon>
                                </span>
                                <span v-else>
                                    <Icon small class="opacity-25" type="sort-up-down"></Icon>
                                </span>
                            </span>

                        </v-list-item-content>
                    </v-list-item>
                    
                </template>
                  </transition-group>
            </draggable>  

        </v-list>
        <template v-slot:footer-left>
            <ActionButton v-if="model.defaultHeaders" link-button @click="()=>model.restoreSettings()">Standaard instellingen</ActionButton>
        </template>
    </Dialog>
</template>



<script setup>
    //
    // This dialog configures the columns used in a datatable. 
    // 
    // Usage: 
    //    import DialogConfigureTable from '@shared/ui/dialogs/DialogConfigureTable'    
    //    <DialogConfigureTable/>
    //
    //    eventbus.dialog.open('configureDatatable', {id: this.name, headers: this.allHeaders, defaultHeaders: this.defaultHeaders })
    //

    import Form from '@controls/forms/Form'
    import Dialog from '@shared/ui/dialogs/ActionDialog'

    import { ref } from 'vue'
    import clsDialog from '@cls/clsDialog'
    import Icon from '@controls/icons/Icon'
    import draggable from 'vuedraggable'
    import Checkbox from "@controls/check/Checkbox.vue";
    import {datatables as dtsettings} from '@app/settings';
    import ActionButton from '@controls/buttons/ActionButton'

    const form = ref(null); 
    var drag = ref(false); 


    /**
     * Small, dedicated model. Keep in line with the dialog.
     */

    class clsConfigureDataTable {
        headers = [];
        id = null;
        isLoading = false;
        defaultHeaders = null;
        noSort = false;

        /**
         * Load the configuration from the datatable and from the local settings.
         * No need to set the isLoading flag.
         */
        async load(id, params) {
            this.id = id;
            if (!params.headers || !params.headers.length) {
                return false;
            }
            let arrHeaders = [];
            for(var n = 0; n < params.headers.length; n++) {
                let hdr = params.headers[n];
                arrHeaders.push({
                    ixOrder: n,
                    value: hdr.value, 
                    text: hdr.text || hdr.configText || hdr.value, 
                    configText: hdr.configText || hdr.text, 
                    sort: hdr.sort, 
                    visible: (undefined == hdr.visible) ? true : hdr.visible, 
                    canhide: (undefined == hdr.canhide) ? true : hdr.canhide, 
                    canmove: (undefined == hdr.canmove) ? true : hdr.canmove
                });
            }
            this.headers = arrHeaders;
            this.defaultHeaders = params.defaultHeaders || null;
            this.noSort = !!params.noSort;
        }

        // Note. How the data is saved, we don't care. 
        // Here, we just provide the column array.
        async save(bKeepOpen) {
            this.isLoading = true;

            let arr = [];
            for(var n = 0; n < this.headers.length; n++) {
                let hdr = this.headers[n];
                let item = {
                    value: hdr.value, 
                    visible: hdr.visible
                };
                if (hdr.sort) {
                    item.sort = hdr.sort;
                }
                arr.push(item);
            }
            try {
                await dtsettings.save(this.id, arr);  
            } finally {
                this.isLoading = false;
            }
        }
        // Restore the factory settings. 
        // This can only be done when defaultHeaders is provided.
        restoreSettings() {
            if (this.defaultHeaders) {
                (this.headers||[]).forEach( (hdr) => {
                    if (this.defaultHeaders[hdr.value]) {
                        let defaultHdr = this.defaultHeaders[hdr.value];
                        hdr.ixOrder = defaultHdr.ixOrder;
                        hdr.visible = defaultHdr.visible;
                        hdr.sort = defaultHdr.sort;
                    }
                });
                this.headers.sort( (a,b) => a.ixOrder - b.ixOrder);
            }
        }

        onSortBy(line) {
            if (line.sort && line.sort == 'desc') {
                line.sort = 'asc';
            } else if (line.sort && line.sort != 'desc') {
                line.sort = 'desc';
            } else {
                this.headers.forEach( (hdr) => hdr.sort = null);
                line.sort = 'desc';                
            }
        }
    }

    let model = ref(new clsConfigureDataTable()).value;
    let dlg = ref(
        new clsDialog("configureDatatable", "Kolom instellingen", model, [form], {
            icon: 'settings',
            noSubTitle: true

        } )).value;    

</script>
