<template>

    <ActionCombo :noClearable="noClearable" :placeholder="placeholder" :skeleton="compSkeleton" :disabled="compDisabled" :rules="rules" v-model="dvalue" :items="items"></ActionCombo>

</template>

<script setup>

    // import YearCombo from '@controls/combo/Year'
    // <YearCombo min="-2" max="2" :model="model" :rules="model.rules.year" v-model="model.year"></YearCombo>

    const props = defineProps({
        disabled: {
            type: [Boolean]
        },
        rules: {
            type: [Array]
        },
        noClearable: {
            type: [Boolean],
            default: false
        },
        skeleton: {
            type: [Boolean]
        },
        value: {
            type: [String, Number]
        },
        min: {
            type: [Number, String],
            default: -1
        },
        max: {
            type: [Number, String],
            default: 1
        },
        placeholder: {
            type: [String],
            default: 'Jaar'
        },
        model: {
            type: [Object]
        }

    })
    
    import ActionCombo from '@controls/combo/ActionCombo'
    import {computed} from 'vue'
    import {date} from '@lib/date'

    const emit = defineEmits(['input'])

    const dvalue = computed({
        get() {
            return props.value
        },
        set: function(v) {
            emit('input', v)
        }	
    })

    const items = computed({
        get() {
            var cy = date.year();
            var from = Math.min(cy + Number(props.min), cy);
            if (props.value) {
                from = Math.min(props.value-1, from);
            }
            var to = Math.max(cy + Number(props.max), cy);
            if (props.value) {
                to = Math.max(props.value+1, to);
            }
            var result = [];
            for (var n = from; n <= to; n++) {
                result.push({id: n, name: `${n}`})
            }
            return result;
        }
    })
    const compSkeleton = computed({
        get() {
            if (props.skeleton) {
                return true;
            }
            if (!props.model) {
                return false;
            }
            return props.model.isDataLoading;
        }
    });
    const compDisabled = computed({
        get() {
            if (props.disabled) {
                return true;
            }
            if (!props.model) {
                return false;
            }
            return props.model.disabled;
        }
    });

</script>
