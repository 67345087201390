<template>
    <div>
        <Datatable :dt="dt" class="full-height">
            <template v-slot:menu ="{ item }">
                <ActionMenuItem action="purchaseinvoice.open" @click="dlg.open('purchaseinvoice', item)"></ActionMenuItem>
            </template>

            <template v-slot:item.pi_status ="{ item }">
                <span class="cursor-pointer" @click="()=>dlg.open('purchaseinvoice', item)">            
                    <StatusChip type="pi" :status="item.pi_status">{{statusRep(item.pi_status)}}</StatusChip>
                </span>
            </template>

            <template v-slot:item.po_number ="{ item }">
                <span v-if="!item.po_number">-</span>
                <ActionButton v-else link-button no-icon action='paymentorder.open' @click="()=>dlg.open('paymentorder', {id: item.id_paymentorder})">{{item.po_number}}</ActionButton>
            </template>

        </Datatable>
    </div>
</template>

<script setup>
    import useDataTable from '@app/useDataTable'
    import Datatable from '@datatable/Datatable'
    import ActionMenuItem from "@controls/menu/ActionMenuItem.vue";
    import { purchaseinvoices as api } from '@app/api'
    import string from '@lib/string'
    import dlg from '@app/dlg'
    import StatusChip from "@controls/widgets/StatusChip.vue";
    import ActionButton from '@controls/buttons/ActionButton'
    import {computed, watch, ref} from 'vue'
    const props = defineProps({
        model: {
            type: [Object]
        },
        activeTab: {
            type: [Boolean]
        },
    });

    var fmtName = function(a,b,item) {
        return string.concat(" ", item.prs_firstname, item.prs_infix, item.prs_lastname);  
    }
    function statusRep(status) {
        switch (string.lower(status)) {
            case "new"        : return "Nieuw";
            case "accepted"   : return "Goedgekeurd";
            case "processed"  : return "Boekhouding";
            case "rejected"   : return "Afgekeurd";
            case "enrichment" : return "Wordt verwerkt";
        }
        return "-";
    }

    var headers = [
    { type: 'cmd'},
        { type: 'flag', flagType: `purchaseinvoice_flag`},
        { type: 'note', noteType: `purchaseinvoice_note`},
        { text: "Status",                   value: "pi_status",      },
        { visible: false, text: "Type",                     value: "type",                  fmt: (v, item) => ((v == 'pin') ? 'pinbon' : (v == 'cash') ? 'kasbon' : 'factuur')} ,
        { text: "Betaald",                  value: "paid_status",           fmt: (v, item) => ((v == 'paid') ? 'Ja' : (v == 'pending') ? 'Onderweg' : 'Nee')},
        { visible: false, text: "Betaalopdracht",           value: "po_number",      },        
        { visible: false, text: "Dispuut",                  value: "dispute",                           },
        { visible: false, text: "Relatie",                  value: "rel_name", },
        { text: "Factuurnr",                value: "invoicenr", },
        { text: "Factuurdatum",             value: "invoicedate",    type: 'date'   },
        { visible: false, text: "Vervaldatum",              value: "invoiceduedate", type: 'date',      },
        { visible: false, text: "Factuurbedrag Incl BTW",                 value: "total_payable",  type: 'amount', crnc_field:"crnc_code" },
        { text: "Factuurbedrag Excl BTW",                 value: "total_excl",     type: 'amount', crnc_field:"crnc_code"     },
        { visible: false, text: "Factuur BTW",                      value: "total_vat",      type: 'amount', crnc_field:"crnc_code"     },
        { text: "Projectbedrag Excl BTW",                 value: "pidl_total",     type: 'amount', crnc_field:"crnc_code"     },
        { text: "Kostensoort",                 value: "ct_name", emptyText: '-'     },
        { visible: false, text: "Relatie BTW nummer",       value: "rel_coc_number",                    },
        { visible: false, text: "incasso",                  value: "incasso",        type: 'yesno'      },
        { visible: false, text: "IBAN",                     value: "iban",                              },
        { visible: false, text: "G bedrag",                 value: "g_amount",       type: 'amount', crnc_field:"crnc_code" },
        { visible: false, text: "G-rekening",               value: "ibang",                             },
        { visible: false, text: "Betalingskorting",         value: "discount",       type: 'amount', crnc_field:"crnc_code" },
        { visible: false, text: "Afzender",                 value: "pi_fromaddress",                    },
        { visible: false, text: "Onderwerp",                value: "pi_summary",                        },

		{ isUtc: true, visible: false, text: "Gewijzigd",                value: "updated_at",    type: 'datetime',   },
		{ visible: false, text: "Gewijzigd door",           value: "updated_by",                        },
		{ isUtc: true, visible: false, text: "Aangemaakt",               value: "created_at",    type: 'datetime',   sort: 'desc'},
		{ visible: false, text: "Aangemaakt door",          value: "created_by",                        },
		{ text: "Boekhoudnummer",           value: "accountancy_number",                visible: false},
    ]

    const compIdChapter = computed({
        get() {
            return props.model.id_pr_chapter;
        }
    })
    
    const lastChapterSearched = ref(null);

    var dt = useDataTable("dt_project_purchaseinvoices", "purchaseinvoice", api, headers, {        
        itemName: {
            prefix: 'De',
            single: 'inkoopfactuur',
            plural: 'inkoopfacturen',
        }, parent: {
            id_project: props.model.id
        },filter: {
            id_project_chapter: ()=>compIdChapter.value,
            selectfor: ()=>'project'
        },
        onAfterSearch: () => lastChapterSearched.value = compIdChapter.value,
        preventRemoveHandler: ()=>!props.model.isDialogOpen, // No refresh when dialog is not open
        preventSaveHandler:   ()=>!props.model.isDialogOpen, // No refresh when dialog is not open
        customSaveHandler:    (_dt, modelName) => { if (modelName == "purchaseinvoice") {_dt.search()} }, // Full refresh
        customRemoveHandler:  () => true, // do nothing.

        itemsPerPageOptions: [ 200, 500],
        itemsPerPage: 500,

        canArchive: false
    })

    // When a chapter is selected and we are the active tab, search.
    watch(compIdChapter, (newValue, oldValue) => {
        if (props.activeTab) {
            dt.search();
        }
    })
    // The currently active tab. Must be reactive in order to be watched
    const compActiveTab = computed({
        get() {
            return props.activeTab
        }
    })
    // When this tab is activated and the last chapter searched is not the actual one, search.
    watch(compActiveTab, (newValue, oldValue) => {
        if (newValue) { // tab is activated
            if (lastChapterSearched.value != compIdChapter.value) { 
                dt.search();
            }
        }
    })


</script>