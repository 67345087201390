import { clsModel, fnCreate } from '@cls/clsModel'
import { mandays as api, salesinvoices as salesInvoiceApi } from '@/app/api'
import Constants from '@app/consts'
import string from '@lib/string'
import { date } from '@lib/date'
import { watch, ref } from 'vue'
import {relation as relationList} from '@app/list';

var modelName = "manday";
const id_optimit_type = Constants.optimit_types.manday;

class clsMandayLine {
    id                    = null;
    id_employee           = null;
    mdrr_work_description = null;
    mdrr_monday           = null;
    mdrr_tuesday          = null;
    mdrr_wednesday        = null;
    mdrr_thursday         = null;
    mdrr_friday           = null;
    mdrr_saturday         = null;
    mdrr_sunday           = null;

    constructor(data) {
        data = data ||{}
        for (var key in data) {
            if (undefined !== this[key]) {
                this[key] = data[key];
            }
        }
    }
    
    get totalMinutes() {
        var v = 0;
        ["mdrr_monday", "mdrr_tuesday", "mdrr_wednesday", "mdrr_thursday", "mdrr_friday", "mdrr_saturday", "mdrr_sunday"].forEach( (key) => {
            v += Number(this[key]);
        })
        return v;
    }

    toJSON() {
        return {
            id                    :this.id,
            id_employee           :this.id_employee,
            mdrr_work_description :this.mdrr_work_description,
            mdrr_monday           :this.mdrr_monday,
            mdrr_tuesday          :this.mdrr_tuesday,
            mdrr_wednesday        :this.mdrr_wednesday,
            mdrr_thursday         :this.mdrr_thursday,
            mdrr_friday           :this.mdrr_friday,
            mdrr_saturday         :this.mdrr_saturday,
            mdrr_sunday           :this.mdrr_sunday,    
        }    
    }
}

class clsManday extends clsModel {

    // Fields which are used as a watch source must be declared as a ref field.    
    id = null;
    id_relation = ref(null);
    id_invoice = null;
    mdr_number = null;
    mdr_title = null;
    mdr_week = ref(null);
    mdr_year = ref(null);
    mdr_comment = null;
    inv_number = null;
    inv_status = null;
    lines = [];
    hm = false;
    
    get isInvoiced() {
        return !!this.id_invoice;
    }
    get isInvoiceSent() {
        return this.isInvoiced && !string.compare(this.inv_status, "concept");
    }
    get invoiceRep() {
        if (!this.isInvoiced) {
            return "";
        }
        var rep = "Factuur";
        if (string.compare(this.inv_status, "concept")) {
            rep = "Concept factuur"; 
        }
        if (!string.isEmpty(this.inv_number)) {
            rep = `${rep} ${this.inv_number}`
        }
        return rep;
    }

    /**
     * Are we disabled?
     * The default disabled rule just checks the modify rights. 
     * When required, overwrite this getter.
     */
    get disabled() {
        if (super.disabled) {
            return true;
        }
        if (this.isInvoiceSent) {
            return true;
        }
        return false;
    }
    
    constructor() {
        super({
          api: api,   
          modelName: modelName, 
          id_optimit_type: id_optimit_type, 
          mandatoryFields: [ 'id_employee', 'id_relation', 'mdr_title', 'mdr_week', 'mdr_year' ] 
        })
    
        watch([this.id_relation , this.mdr_week, this.mdr_year ], (newValue, oldValue) => {
            if (!this.isDataLoading && !this.isLoading && !this.disabled) {
                this.setDefaultTitle();
            }            
        })
    
    } 

    async createInvoice() {
        try {
            this.isDataLoading = true;
            let result = await salesInvoiceApi.createForManday(this.id);
            this.sendSavedEvent();
            return result.data.id_invoice;
        }
        finally {
            this.isDataLoading = false;
        }

    }
    /**
     * set a default title based on the selected relation, year and week. 
     * Note that this is triggered by a watch action, and only when we are not loading or disabled.
     * @returns 
     */
    setDefaultTitle() {
        var year = this.mdr_year || "";
        var week = this.mdr_week || "";
        var id_relation = this.id_relation;
        // Only make this change when all required data is filled. 
        if (!year || !week || !id_relation) {
            return; 
        }
        var relName = relationList.oneProp(id_relation, "rel_name", "");
        this.mdr_title = `${relName} ${year} week ${week}`;
    }

    get modelRep() {        
        let period = string.concat(" / ", this.mdr_number, this.mdr_year, this.mdr_week );

        return period;
    }

    get periodFrom() {
        return date.fmt.local(this.pickPeriod);
    }
    get periodTo() {
        return date.fmt.local(this.pickPeriod, 6);
    }

    get pickPeriod() {
        var yearweekdate = date.isoYearWeekToDate(this.mdr_year, this.mdr_week);
        return yearweekdate;
    }
    set pickPeriod(dt) {
//        console.log('set pickperiod', dt, date.weekYear(dt), date.week(dt))
        this.mdr_year = dt ? date.weekYear(dt) : null;
        this.mdr_week = dt ? date.week(dt) : null;    
    }

    dtDay(offset) {
        if (!this.mdr_year || !this.mdr_week) {
            return "";
        }
        var yearweekdate = date.isoYearWeekToDate(this.mdr_year, this.mdr_week, offset || 0);
        if (!yearweekdate) {
            return "";
        }
        return date.fmt.custom(yearweekdate, "DD-MM");
    }
    /**
     * The date representations for all days in the mandayweek
     */
    get dtMon() {
        return this.dtDay(0) 
    }
    get dtTue() {
        return this.dtDay(1) 
    }
    get dtWed() {
        return this.dtDay(2) 
    }
    get dtThu() {
        return this.dtDay(3) 
    }
    get dtFri() {
        return this.dtDay(4) 
    }
    get dtSat() {
        return this.dtDay(5) 
    }
    get dtSun() {
        return this.dtDay(6) 
    }

    get totalMinutes() {
        var v = 0;        
        (this.lines||[]).forEach( (line) => {
            v += line.totalMinutes;
        })
        return v;
    }

    removeLine(lineRemove) {
        this.lines = this.lines.filter( (line) => line != lineRemove);
    }
    addLine() {
        this.lines.push( new clsMandayLine( {
            mdrr_monday    : 0,
            mdrr_tuesday   : 0,
            mdrr_wednesday : 0,
            mdrr_thursday  : 0,
            mdrr_friday    : 0,
            mdrr_saturday  : 0,
            mdrr_sunday    : 0,        
        }));
    }

    /**
     * Fill the data 
     * 
     * @param {*} data 
     */
    fill(data) {
        data = data || {};
        super.fill(data);
        this.mdr_number = data.mdr_number || "";
        this.id_relation = data.id_relation;
        this.id_invoice = data.id_invoice;
        this.mdr_title = data.mdr_title;
        this.mdr_week = data.mdr_week || date.week();     // Default to
        this.mdr_year = data.mdr_year || date.weekYear(); // current week and year
        this.mdr_comment = data.mdr_comment;
        this.inv_number = data.inv_number;
        this.inv_status = data.inv_status;
        let lines = [];
        (data.lines||[]).forEach( (line) => {
            lines.push( new clsMandayLine(line) );
        })
        this.lines = lines;
        if (this.isNew && !this.lines.length) {
            this.addLine();
        }
    }

    /**
     * Get the JSON representation for saving the data
     * 
     * @param {*} data 
     */
    toJSON() {
        var json = this.propsToJSON(["id", "flag",  "id_relation", "id_invoice", "mdr_title", "mdr_week", "mdr_year", "mdr_comment", "inv_number", "inv_status"]); // TODO

        json.lines = this.lines.map( (line) => line.toJSON());
        // json.lines = [];
        // this.lines.forEach( (line) => {
        //     json.lines.push(line); //
        // })                

        return json;
    }
    
    /**
     * Download the current data in PDF format.
     * @returns 
     */
    async downloadAsPdf(para) {        
        return api.postPDF("examplepdf", this.toJSON());
    }
    /**
     * Download the current data in PDF format and open it to the client.
     * This basicly triggers either a download action or an open action, whatever is appropriate for the browser.
     * @returns 
     */
    async downloadAsPdfToClient(para) {
        try {
            this.isDataLoading = true;
            var filename = `Mandagstaat ${this.modelRep}`.replaceAll('/', '-').replaceAll(" - ", "-");
            await api.downloadPostRaw("examplepdf", {id: this.id}, null, filename);    
            }
        finally {
            this.isDataLoading = false;
        }
    }
        
 }
 export default fnCreate(clsManday , 'clsManday');
