import { clsModel, fnCreate } from '@cls/clsModel'
import { projects as api, project_chapter as projectChapterApi, project_extrawork as extraWorkApi } from '@/app/api'
import Constants from '@app/consts'
import bool from '@lib/bool'
import {relation, projectphase, projectgroup} from '@app/list';
import {numeric, amount, percentage} from '@lib/numeric'
import {time} from '@lib/date'
import noty from '@shared/lib/noty'
import fnCreateResult from './project_result_convert'

var modelName = "project";
const id_optimit_type = Constants.optimit_types.project;
const fields = [
    "id",
    "id_relation",
    "id_contactperson",
    "pro_number",
    "pro_name",
    "pro_reference",
    "pro_start",
    "pro_end",
    "pro_completed",
    "pro_status",
    "id_project_phase",
    "pro_use_relation_in_invoice",
    "pro_contract_sum",
    'adr_street', 
    'adr_street_number', 
    'adr_zipcode', 
    'adr_city', 
    'country_code',

    'id_relation_invoice',
    'id_group',
    'id_employee_manager',
    'id_employee_planner',
    'id_employee_executor',

    'chaptersv2',
    'projectresult',
    'budgetcosts',
    'budgethours',
    
    'isResultDirty',
];

////////////////////////////////////////////////////////////////////////////////
//
//
class clsProject extends clsModel {

    id                          = null;
    _id_relation                 = null;
    id_contactperson            = null;
    pro_number                  = null;
    pro_name                    = null;
    pro_reference               = null;
    pro_start                   = null;
    pro_end                     = null;
    pro_completed               = null;
    pro_status                  = null;
    id_project_phase            = null;
    pro_use_relation_in_invoice = null;
    pro_contract_sum            = null;
    adr_street                  = null;
    adr_street_number           = null;
    adr_zipcode                 = null;
    adr_city                    = null;
    country_code                = null;
    id_relation_invoice         = null;
    _id_group                   = null;
    id_employee_manager         = null;
    id_employee_planner         = null;
    id_employee_executor        = null;

    // This property is local only. That is, it is of no interest to a backend and also not used by a backend. 
    // We use it in the frontend to indicate that the content of the result tab may be dirty and needs to be loaded on opening.
    // We add it to the model so that it is filled with a false value every time the model is loaded. 
    isResultDirty             = false;

    _active_chapter = 0;
    show_budget_overview  = false;
    initialBudget = "";

    chapters    = [];

    budgetcosts = [];
    budgethours = [];

    result = [];
    projectresult = [];

    resultIncludeExtraWorkSubmitted = true;
    resultIncludeExtraWorkAccepted = true;
    resultIncludeExtraWorkRejected = false;
    resultIncludeLessWorkSubmitted = true;
    resultIncludeLessWorkAccepted = true;
    resultIncludeLessWorkRejected = false;
    resultExtraWorkExpanded = false;
    resultLessWorkExpanded = false;
    resultPurchaseExpanded = false;
    resultHoursExpanded = false;
    
    get modelRep() {
        return this.pro_name || "";
    }

    /**
     *  get/set the relation field and propagate defaults for the relation to appropriate fields.
     */
    get id_relation() { return this._id_relation; }
    set id_relation(value) {        
        this._id_relation = value;
        if (this.isFilling) {
            return;
        }
        let rel = relation.one(this.id_relation);
        if (!rel) {
            return;
        }         
        this.adr_street = rel.adr_street;
        this.adr_street_number = rel.adr_street_number;
        this.adr_zipcode = rel.adr_zipcode;
        this.adr_city = rel.adr_city;
        this.country_code = rel.country_code || "NL";
    }
    get rel_name() {
        let rel = relation.one(this.id_relation);
        if (!rel) {
            return '-';            
        }         
        return rel.rel_name;
    }

    get id_group() { return this._id_group; }
    set id_group(value) {        
        this._id_group = value;
        if (this.isFilling) {
            return;
        }
        let group = projectgroup.one(this.id_group);
        if (!group) {            
            return;
        }
        this.id_employee_manager = group.id_employee_manager;
        this.id_employee_planner = group.id_employee_planner;
        this.id_employee_executor = group.id_employee_executor;             
    }

    // Get the relation an invoice should be sent to.
    get id_invoicable_relation() {
        if (this.pro_use_relation_in_invoice) {
            return this.id_relation;
        }
        return this.id_relation_invoice;
    }
    /**
     * Override to create specific implementations. 
     */
    async doCreate(defaults) {
        defaults = defaults || {};
        var startPhase = projectphase.startPhase();
        if (startPhase) {
            defaults.id_project_phase = startPhase.id;
        }
        if (projectgroup.count == 1) {
            defaults.id_group = projectgroup.list[0].id;
        }
        // By default, no chapters are used. We use a dummy chapter to store the project budget.
        defaults.chapters = [{id: null, name: 'Hoofdstuk 1', contract_sum: 0, budgetcosts: [], budgethours: []}];

        defaults.pro_use_relation_in_invoice = 1;
        defaults.country_code = "NL";
        defaults.pro_contract_sum = 0;
        return super.doCreate(defaults);
    }
    
    async checkBeforeSave(data) {
        await api.getWarnings(data);     
    }

    /**
     * Make sure that the vendors array is always present, frontend probably depends on array type. 
     * @param {} data 
     * @returns 
     */
    fill(data) {
        
        data = data ||{};
        data.pro_completed = bool.isTrue(data.pro_completed)
        data.pro_use_relation_in_invoice = bool.isTrue(data.pro_use_relation_in_invoice)
        
        data.isResultDirty = false;

        return super.fill(data);
    }

    /**
     * Create an invoice for this tender
     * @returns 
     */
    async createExtraWorkInvoice(ids) {
        if (this.isNew) {
            return; 
        } 
        this.isDataLoading = true;
        try {
            await extraWorkApi.getWarnings({id_project: this.id, ids: ids}, "invoice/warnings");     
            var result = await extraWorkApi.post('invoice', {id_project: this.id, ids: ids});
//            this.sendSavedEvent({id: this.id});
            return result.data;
        } finally {
            this.isDataLoading = false; 
        }
    }
    /**
     * Create an invoice for this tender
     * @returns 
     */
    async downloadExtraWorkPdf(ids) {
        this.isDataLoading = true;
        try {
            var result = await extraWorkApi.postPDF("download/pdf", {id_project: this.id, ids: ids});
            return result.data;
        } finally {
            this.isDataLoading = false; 
        }
    }


    constructor() {
        super({
          api: api,   
          modelName: modelName, 
          mandatoryFields: ["id_relation", "pro_name", "pro_reference"],
          id_optimit_type: id_optimit_type, 
          fillable: fields
        })
    } 

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    //
    // Chapter / Budget V2 
    //
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    chaptersv2 = [];
    get chaptersv2_total_contract_sum() {
        return (this.chaptersv2||[]).reduce( (acc, bc) => acc + Number(bc.contract_sum)||0, 0); 
    }
    get chaptersv2_total_hours() {
        return (this.chaptersv2||[]).reduce( (acc, bc) => acc + Number(bc.budget_hours)||0, 0); 
    }
    get chaptersv2_total_costs() {
        return (this.chaptersv2||[]).reduce( (acc, bc) => acc + Number(bc.budget_costs)||0, 0); 
    }
    async removeChapterv2(chapter) {
        this.isDataLoading = true;
        try {
            var result = await projectChapterApi.post(`remove`, {id_project: this.id, id_chapter: chapter.id}); 
            this.chaptersv2 = result.data.chaptersv2;

        } finally {
            this.isDataLoading = false; 
        }
    }
    // When at least 2 chapters are available, this is multi chapter. 
    // We don't need to show chapters for project result when there is just one chapter.
    get isMultiChapter() {
        return (this.chaptersv2||[]).length > 1;
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    //
    // Result V2
    //
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    projectresult = {};
    _pr_chapter = 0;            // When > 0, show the corresponding chapter results, othwerise all.

    // an id for the currently active chapter. total: null, main: 0, pr_chapter otherwise.
    get pr_chapter() { 
        if (!this.isMultiChapter) {
            return 0;
        }
        return this._pr_chapter    
    }
    set pr_chapter(v) { 
        this._pr_chapter = v;    
    }
    
    // an id for the currently active chapter. total: null, main: 0, pr_chapter otherwise.
    get id_pr_chapter() { 
        return this.pr_chapter    
    }

    // The sequence of the active chapter - if any 
    get pr_chapter_seq() { 
        if (!this.id_pr_chapter) {
            return null;
        }
        var ch = (this.chaptersv2||[]).find( (x) => x.id == this.id_pr_chapter);
        if (!ch) {
            return null;
        }
        return ch.sequence || null;
    }    

    /**
     * override the implementation when required 
     */
    async onAfterLoad(data, params, extraData) {
        this.pr_chapter = 0;
    }

    // Refresh the result data.
    async refreshResultData() {
        if (this.isNew) {
            return; // not for new projects.
        }
        this.isLoading = true;
        try {
            var {data} = await api.loadProjectResult(this.id);
            this.projectresult = data.projectresult;
            this.isResultDirty = false;
        } finally {
            this.isLoading = false;
        }
    }

    // Get the lines for the project result, depending on the currently active chapter.
    resultLinesFor(chapter) { // 0: all chapters. Otherwise, specific chapter.

        var contract_sum = (this.chaptersv2||[]).filter ( (x) => !chapter || chapter == x.id).reduce( (acc, bc) => acc + Number(bc.contract_sum)||0, 0); 

        ////////////////////////////////////////////////////////////////////////////////////////////////////
        // Revenue
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        var revenue = this.projectresult.revenue.invoiced.filter((x) => !chapter || chapter == x.id_project_chapter).reduce ( (acc, line) => {
            return {
                line_total_excl:         (Number(line.line_total_excl)||0)+acc.line_total_excl, 
                line_total_excl_concept: (Number(line.line_total_excl_concept)||0)+acc.line_total_excl_concept, 
            } 
        }, { line_total_excl: 0, line_total_excl_concept: 0, order_amount_open: 0 })

        if (!chapter && this.projectresult.revenue?.open_in_orders?.length) {
            revenue.order_amount_open = this.projectresult.revenue.open_in_orders[0].amount || 0            
        }

        revenue.warnings = [];
        if (revenue.line_total_excl_concept) {
            var inConcept = amount.fmt(revenue.line_total_excl_concept);
            revenue.warnings.push(`Van de facturen staat nog ${inConcept} in concept.`);
        }
        if (revenue.order_amount_open) {
            var inOrder = amount.fmt(revenue.order_amount_open);
            revenue.warnings.push(`Er staat nog een bedrag van ${inOrder} open in orders.`);
        }
        revenue.showInfo = revenue.warnings.length;
        
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        // purchaseCosts
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        // First we get the list of costs per cost type for the selected chapter. 
        // As the data is grouped per chapter, we need to group again as we might have selected 'all chapters'.
        var objPurchaseSpecification = this.projectresult.costs.purchase.filter((x) => !chapter || chapter == x.id_project_chapter)
            .reduce( (acc, line) => {
                if (!acc[line.ct_name]) {
                    acc[line.ct_name] = {name: line.ct_name, cost_amount: 0, budget_amount: 0, warnings: []};
                } 
                acc[line.ct_name].cost_amount += (Number(line.cost_amount)||0)
                acc[line.ct_name].budget_amount += (Number(line.budget_amount)||0)
                return acc;
            }, {});
            // Now convert to an array
        var purchaseSpecification = Object.keys(objPurchaseSpecification).map( (key)=>objPurchaseSpecification[key]).map( (line) => {
            if (line.budget_amount && (line.cost_amount > line.budget_amount)) {
                line.warnings.push("De geboekte kosten zijn hoger dan de verwachte kosten");
            }
            return line;
        })
        // from the specification, we calculate the totals
        var purchaseCosts = {
            budget_amount: purchaseSpecification.reduce( (acc, line) => (Number(line.budget_amount)||0)+acc, 0),
            cost_amount: purchaseSpecification.reduce( (acc, line) => (Number(line.cost_amount)||0)+acc, 0),
            specification: purchaseSpecification,
            warnings: []
        }
        if (purchaseCosts.budget_amount && (purchaseCosts.cost_amount > purchaseCosts.budget_amount)) {
            purchaseCosts.warnings.push("De geboekte kosten zijn hoger dan de verwachte kosten");
        }

        ////////////////////////////////////////////////////////////////////////////////////////////////////
        // Hours
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        // First we get the list of costs per hour type for the selected chapter. 
        // As the data is grouped per chapter, we need to group again as we might have selected 'all chapters'.
        var objHourSpecification = this.projectresult.costs.hours.filter((x) => !chapter || (chapter == x.id_project_chapter))
            .reduce( (acc, line) => {
                if (!acc[line.ht_name]) {
                    acc[line.ht_name] = {name: line.ht_name, cost_amount: 0, budget_amount: 0, total_minutes:0, total_minutes_concept:0, warnings: []};
                } 
                acc[line.ht_name].cost_amount += (Number(line.cost_amount)||0)
                acc[line.ht_name].budget_amount += (Number(line.budget_amount)||0)
                acc[line.ht_name].total_minutes += (Number(line.total_minutes)||0);
                acc[line.ht_name].total_minutes_concept += (Number(line.total_minutes_concept)||0);
                return acc;
            }, {});
            // Now convert to an array
        var hourSpecification = Object.keys(objHourSpecification).map( (key)=>objHourSpecification[key]).map( (line) => {
            if (line.budget_amount && (line.cost_amount > line.budget_amount)) {
                line.warnings.push("De geboekte urenkosten zijn hoger dan de verwachte kosten");
            }
            if (line.total_minutes_concept) {
                var hours_concept   = time.minutes2hourdecimal(line.total_minutes_concept||0, false)
                line.warnings.push(`${hours_concept} uren zijn nog niet in status 'gereed'.`);
            }
            line.hours = time.minutes2hourdecimal(line.total_minutes||0, false)
            line.hours_concept   = time.minutes2hourdecimal(line.total_minutes_concept||0, false)
            return line;
        })
        // from the specification, we calculate the totals
        var hourCosts = {
            budget_amount: hourSpecification.reduce( (acc, line) => (Number(line.budget_amount)||0)+acc, 0),
            cost_amount: hourSpecification.reduce( (acc, line) => (Number(line.cost_amount)||0)+acc, 0),
            total_minutes: hourSpecification.reduce( (acc, line) => (Number(line.total_minutes)||0)+acc, 0),
            total_minutes_concept: hourSpecification.reduce( (acc, line) => (Number(line.total_minutes_concept)||0)+acc, 0),
            specification: hourSpecification,
            warnings: []
        }
        hourCosts.hours = time.minutes2hourdecimal(hourCosts.total_minutes||0, false)
        hourCosts.hours_concept   = time.minutes2hourdecimal(hourCosts.total_minutes_concept||0, false)

        if (hourCosts.budget_amount && (hourCosts.cost_amount > hourCosts.budget_amount)) {
            hourCosts.warnings.push("De geboekte kosten zijn hoger dan de verwachte kosten");
        }
        if (hourCosts.total_minutes_concept) {                        
            hourCosts.warnings.push(`${hourCosts.hours_concept} uren zijn nog niet in status 'gereed'.`);
//            hourCosts.warnings.push(`Er zijn urenregistraties die nog niet verwerkt zijn (${hourCosts.hours_concept} uur)`);
        }

        ////////////////////////////////////////////////////////////////////////////////////////////////////
        // other costs
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        // First we get the list of costs per cost type for the selected chapter. 
        // As the data is grouped per chapter, we need to group again as we might have selected 'all chapters'.
        var otherCosts = this.projectresult.costs.other.filter((x) => !chapter || chapter == x.id_project_chapter)
            .reduce( (acc, line) => acc + (Number(line.amount)||0),0);

        ////////////////////////////////////////////////////////////////////////////////////////////////////
        // Extra work list
        // 1) Get the extra work for the current chapter
        // 2) Group 
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        // First get the extra work for the current chapter.
        var extraWork = this.projectresult.stats.extra_work.filter((x) => !chapter || chapter == x.id_project_chapter)                
            .reduce( (acc, line) => {
                        // In the accumulator we find all fields we need to add up.
                        Object.keys(acc).forEach( (key) => { acc[key] += (Number(line[key]||0))});
                        return acc;
                    },
                {
                    amount_submitted: 0,
                    amount_submitted_invoiced: 0,
                    amount_submitted_concept: 0,
                    amount_agreed: 0,
                    amount_agreed_invoiced: 0,
                    amount_agreed_concept: 0,
                    amount_rejected: 0,
                    amount_rejected_invoiced: 0,
                    amount_rejected_concept: 0,
                }
            );
            extraWork.total_amount = extraWork.amount_submitted +  extraWork.amount_agreed;
            extraWork.total_amount_invoiced = extraWork.amount_submitted_invoiced +  extraWork.amount_agreed_invoiced;
            extraWork.total_amount_concept = extraWork.amount_submitted_concept +  extraWork.amount_agreed_concept;
            extraWork.warnings=[];
            if (extraWork.total_amount_concept) {
                var inConcept = amount.fmt(extraWork.total_amount_concept);
                extraWork.warnings.push(`Van de meerwerkfacturen staat nog ${inConcept} in concept.`);
            }
            if (extraWork.amount_rejected_invoiced) {
                var rejectedButInnvoiced = amount.fmt(extraWork.amount_rejected_invoiced);
                extraWork.warnings.push(`Van het afgekeurde meerwerk is ${rejectedButInnvoiced} toch gefactureerd.`);
            }

            var lessWork = this.projectresult.stats.less_work.filter((x) => !chapter || chapter == x.id_project_chapter)                
            .reduce( (acc, line) => {
                    acc.amount_submitted += (Number(line.amount_submitted)||0);
                    acc.amount_submitted_invoiced += (Number(line.amount_submitted_invoiced)||0);
                    acc.amount_submitted_concept += (Number(line.amount_submitted_concept)||0);
                    acc.amount_agreed += (Number(line.amount_agreed)||0);
                    acc.amount_agreed_invoiced += (Number(line.amount_agreed_invoiced)||0);
                    acc.amount_agreed_concept += (Number(line.amount_agreed_concept)||0);
                    acc.amount_rejected += (Number(line.amount_rejected)||0);
                    acc.amount_rejected_concept += (Number(line.amount_rejected_concept)||0);
                    acc.amount_rejected_invoiced += (Number(line.amount_rejected_invoiced)||0);
                    return acc;
                    },
                {
                    amount_submitted: 0,
                    amount_submitted_invoiced: 0,
                    amount_submitted_concept: 0,
                    amount_agreed: 0,
                    amount_agreed_invoiced: 0,
                    amount_agreed_concept: 0,
                    amount_rejected: 0,
                    amount_rejected_concept: 0,
                    amount_rejected_invoiced: 0,
                }
            );
            lessWork.total_amount = lessWork.amount_submitted +  lessWork.amount_agreed;
            lessWork.total_amount_invoiced = lessWork.amount_submitted_invoiced +  lessWork.amount_agreed_invoiced;
            lessWork.total_amount_concept = lessWork.amount_submitted_concept +  lessWork.amount_agreed_concept;
            lessWork.warnings=[];
            if (lessWork.total_amount_concept) {
                var inConcept = amount.fmt(lessWork.total_amount_concept);
                lessWork.warnings.push(`Van de meerwerkfacturen staat nog ${inConcept} in concept.`);
            }
            if (lessWork.amount_rejected_invoiced) {
                var rejectedButInnvoiced = amount.fmt(lessWork.amount_rejected_invoiced);
                lessWork.warnings.push(`Van het afgekeurde meerwerk is ${rejectedButInnvoiced} toch gefactureerd.`);
            }
        var extraWorkTotal = {
            total_amount          : lessWork.total_amount +  extraWork.total_amount,
            total_amount_invoiced : lessWork.total_amount_invoiced +  extraWork.total_amount_invoiced,
        }
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        // Hour list
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        // First we get the list of costs per cost type for the selected chapter. 
        // As the data is grouped per chapter, we need to group again as we might have selected 'all chapters'.
        var objHoursPerEmployee = this.projectresult.stats.hours_per_employee.filter((x) => !chapter || chapter == x.id_project_chapter)
            .reduce( (acc, line) => {
                var key = `e${line.id_employee}`;
                if (!acc[key]) {
                    acc[key] = {name: line.employee, total_minutes: 0, total_minutes_concept:0, amount: 0, warnings: []};
                } 
                acc[key].total_minutes += (Number(line.total_minutes)||0);
                acc[key].total_minutes_concept += (Number(line.total_minutes_concept)||0); 
                acc[key].amount += (Number(line.amount)||0);
                return acc;
            }, {});
            // Now convert to an array
        var hoursPerEmployee = Object.keys(objHoursPerEmployee).map( (key)=>objHoursPerEmployee[key]).map( (line) => {
            line.hours = time.minutes2hourdecimal(line.total_minutes||0, false)
            line.hours_concept   = time.minutes2hourdecimal(line.total_minutes_concept||0, false)
    
            if (line.total_minutes_concept) {
                line.warnings.push(`${line.hours_concept} uren zijn nog niet in status 'gereed'.`);
//                line.warnings.push(`Er zijn urenregistraties die nog niet verwerkt zijn (${line.hours_concept} uur)`);
            }
            return line;
        })
        var hoursPerEmployeeTotals = hoursPerEmployee.reduce ((acc, line) => {
                acc.total_minutes += (Number(line.total_minutes)||0);
                acc.total_minutes_concept += (Number(line.total_minutes_concept)||0); 
                acc.amount += (Number(line.amount)||0);
                return acc;
            }
            , { total_minutes: 0, total_minutes_concept: 0, amount: 0, }
        );
        hoursPerEmployeeTotals.hours = time.minutes2hourdecimal(hoursPerEmployeeTotals.total_minutes||0, false)

        ////////////////////////////////////////////////////////////////////////////////////////////////////
        // Supplier list
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        var objSupplierInvoices = this.projectresult.stats.cost_per_supplier.filter((x) => !chapter || chapter == x.id_project_chapter)
            .reduce( (acc, line) => {
                var key = `e${line.rel_name}`;
                if (!acc[key]) {
                    acc[key] = {id:line.id, name: line.rel_name, cnt: 0, amount: 0, warnings: []};
                } 
                acc[key].cnt += (Number(line.cnt)||0);
                acc[key].amount += (Number(line.amount)||0); 
                return acc;
            }, {});
            // Now convert to an array
        var supplierInvoices = Object.keys(objSupplierInvoices).map( (key)=>objSupplierInvoices[key]).map( (line) => {
            return line;
        })
        var supplierInvoiceTotals = supplierInvoices.reduce ((acc, line) => {
                acc.cnt += (Number(line.cnt)||0);
                acc.amount += (Number(line.amount)||0);
                return acc;
            }
            , { cnt: 0, amount: 0, }
        );

        var totalCosts =  (Number(purchaseCosts.cost_amount) || 0) 
                        + (Number(hourCosts.cost_amount) || 0) 
                        + (Number(otherCosts) || 0) ;

        return {
            contract_sum: contract_sum, 
            revenue: revenue,
            purchaseCosts: purchaseCosts,
            hourCosts: hourCosts,
            otherCosts: otherCosts,
            totalCosts: totalCosts, 
            result: (Number(revenue.line_total_excl) || 0)  - totalCosts, 

            extraWork: extraWork,
            lessWork: lessWork,
            extraWorkTotal: extraWorkTotal,

            hoursPerEmployee,
            hoursPerEmployeeTotals,

            supplierInvoices,
            supplierInvoiceTotals,

        }
    }
    /**
     * When there is no chapter, or one chapter, do not return chapters. 
     * Just return the project totals.
     */
    get chapterResult() {
        return this.resultLinesFor(this.pr_chapter);
    }



 }
 export default fnCreate(clsProject , 'clsProject');
