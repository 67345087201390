<template>    
    <span class="flat-amount">{{rep}}</span>
</template>

<script setup>
import {time} from '@lib/date'
import {computed, ref} from 'vue'

//
// Usage: 
// import Time from "@controls/text/Time.vue";
// <Time v-model="hm"></Time>
//


const props = defineProps({
    value: {
        type: [Number, String]
    },
    hm: {
        type: [Boolean],
        default: false
    },       

})
const rep = computed({
    get() {
        if (props.hm) {
            return time.minutes2hourminutes(props.value, false);
        } else {
            return time.minutes2hourdecimal(props.value, false)
        }
    },
})

</script>