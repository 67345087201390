<template>
    <v-dialog class="action-dialog action-dialog-small"
        content-class="action-dialog-small`" 
        persistent 
        scrollable
        v-model="open" 
        max-width="650"
        @keydown.escape="(evt) => open = false">
            <v-card flat>

                <v-card-title flat class="px-4 pt-0 pb-0">
                    <slot name="header-title">
                        <v-list class="py-0 dialog-header-title">
                            <v-list-item class="px-0 py-2">
                                <v-list-item-action class="icon-container ma-0 pa-2 mr-4" >
                                    <Icon color="primary" type="alert"></Icon>
                                </v-list-item-action>                                            
                                <v-list-item-content class="py-0">
                                    <v-list-item-title class="mb-0">
                                        <slot name="header-maintitle">
                                            <h1 class="xcapitalize">Versie update</h1>
                                        </slot>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>                                    
                        </v-list>
                    </slot>
                </v-card-title>                           

                <v-card-text flat class="pa-0">
                    <v-divider class="mb-0"></v-divider>
                </v-card-text>
                
                <v-card-text class="content pt-4">
                    Er is een nieuwe versie van Optimit beschikbaar.<br>
                    Deze versie wordt nu geinstalleerd.
                </v-card-text>
                <v-card-text class="content pt-4">
                    Een moment geduld aub...
                    <v-progress-linear
                        color="primary accent-4"
                        :value="progress"
                        rounded
                        height="16"
                    ></v-progress-linear>
                </v-card-text>
            
        </v-card>

    </v-dialog>
</template>

<script setup>
////////////////////////////////////////////////////////////////////////////////////////////////////
//
// Example:
// <DialogVersionUpdate />
//
// import DialogVersionUpdate from '@/app/ui/dialogs/DialogVersionUpdate'
// 
// dlg.open('versionupdate');
// 
//
////////////////////////////////////////////////////////////////////////////////////////////////////

import Icon from '@controls/icons/Icon'
import {ref, onMounted} from 'vue'
import eventbus from '@app/eventbus'
import url from '@shared/lib/url'

const open = ref(false);
const progress = ref(0);
const myName = "versionupdate";

function step() {
    if (progress.value >= 100) {
        progress.value = 0;
        open.value = false;
        url.redirectToVersionedUrl();
    } else {
        progress.value += 3;
        setTimeout( ()=>step(), 200);
    }
}

onMounted( () => {
    eventbus.dialog.open.on( (modelName, params, extraData) => {
        if (modelName == myName) {
            open.value = true;
            progress.value = 0;
        }         
        setTimeout( step, 200 );        
    })
})

</script>