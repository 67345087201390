import { clsModel, fnCreate } from '@cls/clsModel'
import { help as api } from '@/app/api'
import user from '@app/user'
import noty from '@shared/lib/noty'

var modelName = "help";

class clsHelp extends clsModel {

    context=""
    question=""
    email=""
    
    constructor() {
        super({
          api: api,   
          modelName: modelName,
        })
    } 

    get hasVideo() {
        return this.question=="video";
    }

    /**
     * Override load to retrieve the address via 2 keys - a relation id and an address id.
     * @param {} id 
     * @param {*} params 
     * @returns 
     */
    async load(id, params, extraData) {
        this.context = params?.context ||"";
        this.fill();
//         console.log('help load', id, params, extraData)
    }
    
    onAfterSave(resultData) {
        noty.snack.info("Uw vraag is doorgestuurd naar onze klantenservice. U hoort zo snel mogelijk van ons.");
    }

    fill(data) {
        this.email = user.email || "";
        this.question = "";

        super.fill(data)
    }
 
//    async save() {
//        throw new "help save N/A";
//    }

    toJSON() {
        return {
            email: this.email,
            question: this.question,
            clienturl: window.location.toString(),
        }
    }

 }
 export default fnCreate(clsHelp , 'clsHelp');
