import clsApi from '@cls/clsApi'

class clsAccountantApi extends clsApi {

    load() {
        return this.get(`load`); 
    }

    remove() {
        return this.post(`remove`); 
    }

    invite(email, access_level) {
        return this.post(`invite`, {email: email, access_level: access_level}); 
    }

    setAccessLevel(access_level) {
        return this.post(`accesslevel`, {access_level: access_level}); 
    }

}

export default clsAccountantApi;
